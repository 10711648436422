<template>
  <base-popup
    :class="$bem()"
    v-bind="{
      overlay: true,
      freezeBody: true,
      contentClass: [].concat(contentClass || [], $bem('wrapper')),
      ...$attrs,
      persistent
    }"
    v-on="$listeners"
  >
    <template #activator="binding" v-if="$hasSlot('activator')">
      <slot name="activator" v-bind="binding" />
    </template>

    <div :class="$bem('header')">
      <div class="row f-center-space-between">
        <div class="col">
          <slot name="header" />
        </div>

        <div class="col-auto" v-if="!persistent && !hideCloseButton">
          <e-btn
            class="mb-1 mt-1 btn-close btn-right"
            @click="$emit('close')"
          >
            x
          </e-btn>
        </div>
      </div>
    </div>

    <div :class="$bem('content')">
      <slot v-if="!$hasSlot('content')" />

      <slot name="content" />
    </div>

    <div v-if="$hasSlot('footer')" :class="$bem('footer')">
      <slot name="footer" />
    </div>
  </base-popup>
</template>

<script>
import component from '../../../mixins/component';

import basePopup from '../base-popup';
import eBtn from '../../buttons/e-btn-navigate';

export default {
  name: 'e-popup',
  inheritAttrs: false,
  mixins: [component],
  components: { basePopup, eBtn },
  props: {
    persistent: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: [String, Array, Object],
      default: undefined
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    }
  }
};
</script>
