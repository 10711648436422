<template>
  <div :class="$bem()">
    <label :class="[$bem('label'), labelFocused? $bem('label-focused'): '']">{{ title }}</label>
    <div :class="$bem('wrapper')">
      <span v-if="required" :class="$bem('required-icon')">*</span>
      <input-wrapper
        v-bind="{ ...$attrs, showErrorMarker: false }"
        v-on="$listeners"
      >
        <template #input="{ on, isError }">
          <multiselect
            :class="$bem('input')"
            v-model="model"
            :close-on-select="true"
            :clear-on-select="false"
            :show-no-results="false"
            :show-labels="false"
            v-on="$listeners"
            @open="open"
            @close="close"
            v-bind="{
              placeholder,
              label,
              trackBy,
              options,
              multiple,
              ...$attrs
            }"
          />
        </template>
      </input-wrapper>
    </div>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect';
import component from '@/mixins/component';
import InputWrapper from '../inputs/input-wrapper/input-wrapper';

export default {
  name: 'e-multiselect',
  components: { InputWrapper, multiselect },
  mixins: [component],
  data() {
    return {
      labelFocused: false
    }
  },
  computed: {
    model: {
      get() {
        if (this.value && typeof this.value === "string") {
          return {
            name: this.options.find(i => (i.id === this.value)).name,
            id: this.value
          }
        } else {
          return this.value;
        }
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    isError() {
      const { error } = this;

      if (Array.isArray(error)) return !!error.length;

      return !!error;
    }
  },
  props: {
    value: {
      type: [Object, String],
      default: () => {}
    },
    multiple: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    trackBy: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    open() {
      this.labelFocused = true
    },
    close() {
      this.labelFocused = false
    }
  }
};
</script>
