<template>
  <div :class="$bem()">
    <div :class="$bem('blocks')">
      <div v-if="apartment.apt_type" :class="$bem('block')">
        {{ getAptType }}
      </div>

      <div
        v-if="
          apartment.material_type && material_labels[apartment.material_type]
        "
        :class="$bem('block')"
      >
        {{ material_labels[apartment.material_type] }}
      </div>
    </div>

    <table :class="$bem('options-table')">
      <tr>
        <td
          v-if="
            apartment.rooms || apartment.rooms || apartment.type === 'house'
          "
        >
          <img src="@/assets/icons/rooms.svg" alt="rooms-icon" />
          {{
            apartment.rooms ? `${apartment.rooms} ${roomEnding}` : 'Не вказано'
          }}
        </td>

        <td v-if="square">
          <img src="@/assets/icons/square.svg" alt="square-icon" />

          <span v-html="square" />
        </td>
      </tr>

      <tr>
        <td v-if="apartment.floor || apartment.floors">
          <img src="@/assets/icons/floor.svg" alt="floor.svg" />
          {{ apartment.floor ? `${apartment.floor} поверх з ` : '' }}
          {{ apartment.floors }}
          <template v-if="apartment.type === 'house'">поверховість</template>
        </td>

        <td v-if="!!repairItem">
          <img src="@/assets/icons/repair.svg" alt="repair.svg" />
          {{ repairItem }}
        </td>
      </tr>
    </table>

    <div :class="$bem('divider')"></div>

    <table :class="$bem('price-table')">
      <tr>
        <td>
          <div :class="$bem('price-date')">
            <div>Актуально на:</div>

            <div>
              {{
                apartment.updated != null ? apartment.updated.split(' ')[0] : ''
              }}
            </div>
          </div>
        </td>

        <td>
          <div class="d-flex justify-content-end align-items-center">
            <div :class="$bem('price')">
              {{ apartment.price }} {{ getCurrency() }}
            </div>

            <button
              :id="`tooltip-target-price-mobile-${apartment.id}`"
              :class="$bem('info-btn')"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                :class="$bem('svg')"
              >
                <path
                  d="M7 6.27778V9.88889M7 13.5C6.14641 13.5 5.30117 13.3319 4.51256 13.0052C3.72394 12.6786 3.00739 12.1998 2.40381 11.5962C1.80023 10.9926 1.32144 10.2761 0.994783 9.48744C0.668128 8.69883 0.5 7.85359 0.5 7C0.5 6.14641 0.668128 5.30117 0.994783 4.51256C1.32144 3.72394 1.80023 3.00739 2.40381 2.40381C3.00739 1.80022 3.72394 1.32144 4.51256 0.994783C5.30117 0.668127 6.14641 0.5 7 0.5C8.72391 0.5 10.3772 1.18482 11.5962 2.40381C12.8152 3.62279 13.5 5.27609 13.5 7C13.5 8.72391 12.8152 10.3772 11.5962 11.5962C10.3772 12.8152 8.72391 13.5 7 13.5ZM7.03611 4.11111V4.18333H6.96389V4.11111H7.03611Z"
                  stroke="#00D2CD"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>

          <span v-if="apartment.price_old">
            <span :class="$bem('old-price')">
              {{ apartment.price_old }} {{ getCurrency() }}
            </span>
          </span>

          <b-tooltip
            :target="`tooltip-target-price-mobile-${apartment.id}`"
            triggers="click"
            placement="bottom"
            :custom-class="$bem('price-popup')"
          >
            {{ pricePerMeter }}
          </b-tooltip>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import component from '@/mixins/component';
import { ukrainianEndings } from '@/utils/ukrainian-endings.js';
import {
  APT_TYPE_CONSTANTS_DOUBLE,
  HOUSE_TYPES,
  OBJECT_TYPES
} from '../../helpers/constants';

export default {
  name: 'apartment-table',
  mixins: [component],
  props: [
    'apartment',
    'material_labels',
    'house_type',
    'square',
    'repairItem',
    'short_commerce_types',
    'commerce_types',
    'pricePerMeter'
  ],
  computed: {
    getAptType() {
      switch (this.apartment.type) {
        case 'flat':
          return APT_TYPE_CONSTANTS_DOUBLE[this.apartment.apt_type];
        case 'house':
          return HOUSE_TYPES[this.apartment.apt_type];
        case 'commerce':
          return OBJECT_TYPES[this.apartment.apt_type];
      }
    },
    roomEnding() {
      if (!this.apartment.rooms) return '';

      return ukrainianEndings(this.apartment.rooms, [
        'кімната',
        'кімнати',
        'кімнат'
      ]);
    }
  },
  methods: {
    getCurrency() {
      const usd = this.apartment.business_type !== 'rent' ? '$' : '';
      const grn = this.apartment.business_type === 'rent' ? 'грн' : '';

      return usd + grn;
    }
  }
};
</script>

<style lang="sass">
.apartment-table
  &__blocks
    display: flex
    gap: 4px

  &__block
    background: $light-blue-dark
    color: $black
    font-size: 13px
    padding: 3px 5px
    border-radius: 5px
    width: fit-content
    text-transform: lowercase
    margin: 5px 0

  table
    width: 100%
  &__price-table
    td:last-child
      text-align: right
  &__options-table
    font-size: 13px
    font-weight: 300
    td
      min-width: 120px
    img
      margin-right: 2px
  &__divider
    border-top: 1px solid $border
    width: calc(100% - 5px)
    margin: 5px auto 2px

  &__price-popup
    .tooltip-inner
      background: $active_text_color
      font-size: 14px
      font-weight: 300
      padding: 10px
    .arrow
      &:before
        border-bottom-color: $active_text_color !important
  &__svg
    fill: transparent

  &__price
    font-size: 15px
    font-weight: 700

  &__old-price
    text-decoration: line-through
    font-size: 13px
    margin-right: 21px

  &__price-date
    font-size: 13px
    font-weight: 300

  &__info-btn
    display: flex
    align-items: center
    justify-content: center
    outline: none
    border: none
    padding: 0.5rem 0.75rem
    background: none
</style>
