<template>
  <div class="col-auto stock-container mt-2 stock-page">
    <div class="cabinet-pay-wrapper m-auto">
      <h2 v-if="false" class="filter-main-title" id="payments">Акції</h2>
      <span
        v-if="false"
        class="stock-container__back mt-1 mb-1"
        @click="$router.push('/profile')"
        >Перейти до профілю</span>
      <div class="filter-marg"></div>
      <div class="d-flex flex-column flex-sm-row justify-content-start stock-wrapper">
        <div
          class="cabinet-pay-block cabinet-pay-block--stock d-flex flex-column"
        >
          <div class="cabinet-pay-block--header">
            <p class="cabinet-pay-block--header--title">
              🤑 Такого ви ще не бачили! 🤑
            </p>
            <p class="cabinet-pay-block--header--p">
              Знаєте про реальний об’єкт, якого немає у нас у базі? Ми готові платити за нього Easycoinами! 🥳
            </p>

            <p class="cabinet-pay-block--header--p">
              Що таке Easycoin? Ізікоїн це наша внутрішня валюта, за яку можна придбати доступ до бази! 1 коїн = 1 грн
            </p>
          </div>
          <div class="cabinet-pay-block--content">
            <h2 class="cabinet-pay-block--header--title--h2">
              Скільки сплачуємо Вам за доданий об'єкт?
            </h2>
            <div class="stock-items">
              <StockItem
                v-for="(item, index) in stockItems"
                :key="index"
                :item="item"
              />
            </div>
            <p class="cabinet-pay-block--header--p">*нарахування відбувається за умови самостійного додавання об’єкту через сайт</p>
            <StockItem
              fluid
              :item="fluidItem"
            />
            <div class="cabinet-pay-block--info-item">
            <span class="cabinet-pay-block--info-item--title">
              За які об'єкти нараховується оплата?
            </span>
              <p class="cabinet-pay-block--info-item--description">
                За унікальні, яких немає у базі\архіві! Квартиру потрібно додати через форму на сайті натиснувши ( + об'єкт) або надіслати всю інформацію у підтримку сайту! Навіть якщо це об'єкт вашого колеги, після підтвердження вона буде додана та нараховані бонуси!
              </p>
            </div>
            <div class="cabinet-pay-block--info-item">
            <span class="cabinet-pay-block--info-item--title">
              За які об'єкти не нараховується оплата?
            </span>
              <p class="cabinet-pay-block--info-item--description">
                Об'єкти "на трьох", "Заманухи", "дублі". Об'єкти, які будуть продаватися "колись". Об'єкти, у яких проблемні документи, заставне майно, арешт тощо.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
    <MobileHeader
      :objectsCount="objects.results.length"
      :totalCount="resultsCount"
      v-if="windowWidth < 991"
    />
  </div>
</template>

<script>
import StockItem from '@/components/stock/stock-item.vue'
import coin from '@/assets/icons/stockCoin.svg'
import MobileHeader from '@/components/app/mobile-header/mobile-header.vue';
import { mapState } from 'vuex';
export default {
  name: 'StockPage',
  components: {
    MobileHeader,
    StockItem
  },
  data() {
    return {
      windowWidth: null,
      stockItems: [
        {
          img: coin,
          title: '+ 45 EasyCoin',
          description: 'за додану квартиру'
        },
        {
          img: coin,
          title: '+ 35 EasyCoin',
          description: 'за доданий будинок'
        },        {
          img: coin,
          title: '+ 10 EasyCoin',
          description: 'за об’єкт пригороду*'
        },
        {
          img: coin,
          title: '+ 30 EasyCoin',
          description: 'за доданий об\'єкт продаж/оренда комерційної нерухомості'
        },        {
          img: coin,
          title: '+ 20 EasyCoin',
          description: 'за доданий об\'єкт оренди окрім комерції'
        },
        {
          img: coin,
          title: '+ 2 EasyCoin',
          description: 'за неактуальний об’єкт'
        }
      ],
      fluidItem: {
        img: coin,
        title: '+ 10 EasyCoin',
        description: 'за додавання об’єкту через підтримку, незалежно від типу об’єкту'
      }
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    },
  },
  computed: {
    ...mapState({
      objects: (state) => state.my_objects.objects
    }),
    resultsCount() {
      return this.objects.count || 0;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  }
}
</script>

<style lang="sass" scoped>
.cabinet-pay-block--stock
  font-size: 14px
  width: 100%
  max-width: 760px
  text-align: left
  padding: 100px 0 100px
  @media (max-width: 768px)
    padding: 50px 12px 60px

.cabinet-pay-block--header
  font-size: 16px

.stock-container .cabinet-pay-wrapper
  padding: 0


.stock-container__back
  text-align: left
  text-decoration: underline

.cabinet-pay-block--header
  &--title
    font-weight: 300
    font-size: 32px
    line-height: 109%
    color: $white_easy

    @media (max-width: 768px)
      font-size: 23px
      line-height: 152%
      text-align: center
      margin-bottom: 30px

    &--h2
      font-weight: 600
      font-size: 22px
      line-height: 140%
      font-variant: small-caps
      color: $white_easy
      margin-bottom: 25px

      @media (max-width: 768px)
        margin-top: -16px
        font-size: 22px
        line-height: 140%
        font-variant: small-caps

  &--p
    font-weight: 300
    font-size: 16px
    line-height: 140%
    color: #f5f5f5
    padding: 0
    margin: 0 0 16px

    @media (max-width: 768px)
      line-height: 140%

.cabinet-pay-block--content
  padding-top: 40px

  @media (max-width: 768px)
    padding-top: 30px

.stock-items
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: space-between

.cabinet-pay-block--info-item
  width: 100%
  display: flex
  flex-direction: column
  border-top: 1px solid $border_info
  padding-top: 42px
  margin-bottom: 32px
  @media (max-width: 768px)
    padding-top: 24px
    margin-bottom: 12px

  &:last-child
    border-bottom: 1px solid $border_info

    .cabinet-pay-block--info-item--description
      margin-bottom: 32px

  &--title
    font-weight: 300
    font-size: 22px
    line-height: 140%
    color: $title-info
    margin-bottom: 32px
    @media (max-width: 768px)
      margin-bottom: 22px

  &--description
    font-weight: 300
    font-size: 18px
    line-height: 140%
    color: $white_easy
    @media (max-width: 768px)
      font-size: 16px

.stock-page
  .mobile-header
    top: 0
    right: 0
</style>
