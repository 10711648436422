const getDefaultState = () => {
  return {
    form: {
      price_from: '',
      price_to: '',
      sortable: ''
    }
  };
};

export const state = getDefaultState();

export const getters = {
  existsTurnOnFilterPrice(state) {
    return !!(
      state.form.price_from ||
      state.form.price_to
    );
  },
  getSortable(state) {
    return state.form.sortable;
  }
};

export const mutations = {
  CHANGE_PRICE_FROM(state, payload) {
    state.form.price_from = payload;
  },
  CHANGE_PRICE_TO(state, payload) {
    state.form.price_to = payload;
  },
  CHANGE_SORTABLE(state, payload) {
    state.form.sortable = payload;
  },
  RESET_FILTER_PRICE(state) {
    state.form.price_from = '';
    state.form.price_to = '';
  }
};
