<template>
  <div :class="$bem()">
    <div
      :class="$bem('quantity')"
      :style="{ backgroundImage: `url(${quantityBg})` }"
    >
      <div :class="$bem('inner')">
        <h2>Кількість інформації</h2>
        <p>
          Ми щомісяця додаємо понад 3000 нових перевірених об'єктів, а також
          актуалізуємо інсуючі, щоб Ваш досвід пошуку був ще кращим. Наша
          система також переносить об'єкти в архів, щоб Ваш пошук був
          максимально ефективним. Зареєструйтеся на нашому сайті та отримайте
          доступ до цієї найбільшої бази нерухомості Дніпра!
        </p>
        <div :class="$bem('dt-invisible')">
          <div
            :class="$bem('qt-image')"
            :style="{ backgroundImage: `url(${quantityBg})` }"
          />
        </div>
      </div>
    </div>
    <div :class="$bem('inner')">
      <div :class="$bem('pay')">
        <img src="@/assets/landing/pay.png" alt="pay" />
        <div>
          <h2>Платимо Вам за інформацію</h2>
          <p>
            Звичайно, наше партнерство - не однобічне. Ми розуміємо, що без вас,
            наші користувачі, наша база нерухомості була б неповною. Тому ми
            також винагороджуємо наших колег-ріелторів за надання нам цінної
            інформації про нові об'єкти. Накопичені бонуси можуть бути
            використані для оплати доступу до нашої бази, що є ще одним
            способом, яким ми показуємо нашу вдячність і підтримку наших
            партнерів.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'landing-info-screen',
  mixins: [component],
  computed: {
    quantityBg() {
      return require('@/assets/landing/laptop.png');
    }
  }
};
</script>
