<template>
  <input-wrapper
    :class="[$bem(), { checked }]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #input="{ isError }">
      <label
        :class="[$bem('label'), color, { 'w-100': fullWidth }]"
        class="row grid-small f-center-space-between"
        v-bind="{ disabled }"
      >
        <div class="col-auto f-center-center">
          <input
            :class="[$bem('input'), 'hidden']"
            type="checkbox"
            v-bind="{ ...$attrs, required, disabled, checked }"
            v-on="{ ...$listeners, input: () => undefined, change: input }"
          />
          <div v-show="checked" :class="[$bem('icon')]">
            <svg :class="$bem('check-icon')">
              <use xlink:href="@/assets/icons/check.svg#check"></use>
            </svg>
          </div>

          <div v-show="!checked" :class="[$bem('icon')]" />
        </div>

        <div class="col f-center-left">
          <div
            :class="[
              $bem('text'),
              { required },
              { 'w-100': fullWidth }
            ]"
          >
            <span :class="$bem(['text-content'])" v-if="!!label" v-html="label" />
            <slot v-bind="{ isError }" />

          </div>
        </div>
      </label>
    </template>
  </input-wrapper>
</template>

<script>
import component from '@/mixins/component';

import { objectEqual as equal } from '@/utils/object-equal';

import inputWrapper from '../input-wrapper';

export default {
  name: 'e-checkbox',
  inheritAttrs: false,
  mixins: [component],
  components: { inputWrapper },
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  computed: {
    checked() {
      const { value, modelValue, trueValue } = this;
      if (!Array.isArray(modelValue))
        return equal(trueValue, modelValue !== undefined ? modelValue : value);

      return modelValue.some((row) => equal(row, value));
    }
  },
  methods: {
    input({ target: { checked } }) {
      const { value, modelValue, trueValue, falseValue } = this;

      if (!Array.isArray(modelValue)) {
        this.$emit('input', checked ? trueValue : falseValue);
        this.$emit('change', checked ? trueValue : falseValue);
      } else {

        const array = [...modelValue];

        if (checked) {
          array.push(value);
        } else {
          array.splice(
            array.findIndex((row) => equal(row, value)),
            1
          );
        }

        this.$emit('input', array);
        this.$emit('change', array);
      }
    }
  },
  props: {
    value: undefined,
    modelValue: undefined,
    falseValue: {
      default: false
    },
    trueValue: {
      default: true
    },
    label: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    }
  }
};
</script>
