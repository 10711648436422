<template>
  <div :class="$bem()">
    <div :class="$bem('inner')">
      <h2 id="about">Про нас</h2>
      <div :class="$bem('container')">
        <img src="@/assets/landing/about-1.jpg" alt="about-1" />
        <p>
          Ми - молода та амбіційна команда, яка вкладає свої зусилля для того,
          щоб зекономити час рієлторів у пошуку нерухомості. Наша мета - надати
          їм більше часу для роботи з клієнтами, сім'єю та освітою, щоб вони
          могли розвиватися професійно.
        </p>
      </div>
      <div :class="$bem('container')" v-show="showMore">
        <p :class="$bem('mb-invisible')">
          Ми завжди прагнемо захищати інтереси наших клієнтів та зробити ринок
          нерухомості та рієлторський бізнес ще привабливішими. Дослухатися до вас і
          задовольнити ваші потреби - наш пріоритет. Ми пильно слідкуємо за
          вашими зауваженнями та постійно вдосконалюємо наш сайт та інструменти.
        </p>
        <img src="@/assets/landing/about-2.jpg" alt="about-2" />
        <p :class="$bem('dt-invisible')">
          Ми завжди прагнемо захищати інтереси наших клієнтів та зробити ринок
          нерухомості та рієлторський бізнес ще привабливішими. Дослухатися до вас і
          задовольнити ваші потреби - наш пріоритет. Ми пильно слідкуємо за
          вашими зауваженнями та постійно вдосконалюємо наш сайт та інструменти.
        </p>
      </div>
      <div :class="$bem('container')" v-show="showMore">
        <img src="@/assets/landing/about-3.jpg" alt="about-3" />
        <p>
          Разом з нами ви отримуєте доступ до передових рієлторських
          інструментів, які спрощують вашу роботу та підвищують ефективність. Ми
          пишаємось тим, що можемо бути вашими надійними партнерами на шляху до
          успіху.
        </p>
      </div>
      <div :class="$bem('container')" v-show="showMore">
        <img
          src="@/assets/landing/about-4.jpg"
          alt="about-4"
          :class="$bem('dt-invisible')"
        />
        <p>
          Приєднуйтесь до нас сьогодні і переконайтеся, що ми завжди прагнемо
          досягти кращих результатів для вас та вашого бізнесу. Разом ми зробимо
          крок у майбутнє нерухомості!
        </p>
        <img
          src="@/assets/landing/about-4.jpg"
          alt="about-4"
          :class="$bem('mb-invisible')"
        />
      </div>
      <button @click="showMore = !showMore" :class="$bem('more')">
        {{ showMore ? 'Показати меньше' : 'Показати більше' }}
      </button>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
export default {
  name: 'landing-about-screen',
  mixins: [component],
  data() {
    return {
      showMore: false
    };
  }
};
</script>
