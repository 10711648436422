<template>
  <div :class="[$bem(), 'mr-auto', 'ml-auto']">
    <e-text is="h3" :class="$bem('title')">
      {{ isEdit ? 'Редагувати' : 'Додати' }} об`єкт
    </e-text>

    <div :class="$bem('form-container')">
      <!-- Block Загрузка фото -->
      <context-wrapper
        :class="['row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Загрузка фото"
      >
        <div class="col-12">
          <image-uploader
            v-model="newImages"
            :images="form.images"
            @update-sorting="newSorting = $event"
            :apartment-id="form.id"
            :limit="imagesLimit"
          />

          <small class="form-disclaimer">
            * Максимальна кількість фото {{ imagesLimit }} шт.
          </small>
        </div>
      </context-wrapper>

      <!-- Block Параметри 1 -->
      <context-wrapper
        :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Параметри 1"
      >
        <!-- align-items-center -->
        <div class="row col-12 mt-3 d-flex">
          <type-real-estate
            v-model="form.type"
            class="col-12 col-lg-4"
            v-bind="{ label: 'Тип', required: true, error: errors.type }"
            :disabled="isEdit"
          />

          <div class="wrapper-business-type pb-3">
            <div
              :class="{
                'wrapper-business-type__border-error': errors.business_type
              }"
            >
              <div
                v-if="errors.business_type"
                class="wrapper-business-type__error-message"
              >
                <span>Поле обов'язкове для заповнення</span>
              </div>

              <div class="wrapper-business-type__border">
                <business-type
                  v-model="form.business_type"
                  v-bind="{ required: true, error: errors.business_type }"
                  :disabled="isEdit"
                  class="col-12 col-lg-2 parameter-1"
                />
              </div>
            </div>
          </div>

          <div v-if="isRent" class="attention col-12 col-lg-4">
            <span>
              Будь ласка, зверніть увагу: об'єкти оренди автоматично переходять
              в архів, якщо їх актуальність не оновлюється протягом 10 днів. Ви
              можете легко підтримувати актуальність своїх об'єктів за допомогою
              нашого Telegram-бота.
            </span>
          </div>
        </div>
      </context-wrapper>

      <!-- Block Адреса -->
      <context-wrapper
        :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Адреса"
      >
        <location-select
          v-model="modelCity"
          v-bind="{ title: 'Локація', required: true, error: errors.city }"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
        />
        <districts-select
          v-model="form.district"
          v-bind="{ title: 'Район', required: true, error: errors.district }"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
        />
        <div
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
          v-if="form.city && form.city.is_city"
        >
          <microdistricts-select
            v-model="form.microdistrict"
            v-bind="{
              title: 'Мікрорайон',
              required: true,
              error: errors.microdistrict
            }"
          />
        </div>
        <streets-select
          v-model="form.street"
          v-bind="{
            title: 'Вулиця',
            required: true,
            error: errors.street
          }"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
        />
        <numeric-type-field
          v-model="form.house_number"
          v-bind="{ label: 'Номер будинку', error: errors.house_number }"
          :class="[
            calculateClassForSmallComponent,
            'mt-4',
            'col-6',
            'col-md-2'
          ]"
        />
        <house-letters
          v-model="form.house_letter"
          :class="[
            calculateClassForSmallComponent,
            'mt-4',
            ,
            'col-6',
            'col-md-2'
          ]"
        />
        <e-input
          v-if="isApartments"
          v-model="form.house_hull"
          label="Корпус"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
        />
        <residential-complex-select
          v-if="!isCommerce"
          v-model="form.residential_complex"
          title="ЖК"
          :is-building="false"
          :error="errors.residential_complex"
          :required="form.apt_type === 'new_building'"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
        />
        <residential-complex-select
          v-else-if="isCommerce"
          v-model="form.building"
          title="ЖК, ТЦ, ТРЦ, БЦ"
          :is-building="true"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
        />
      </context-wrapper>

      <!-- Block Параметри 2 -->
      <context-wrapper
        v-if="form.type"
        :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Параметри 2"
      >
        <type-apartments
          v-if="isCommerce"
          v-model="form.apt_type"
          v-bind="{
            label: 'Тип об\'єкта',
            type: form.type,
            required: true,
            error: errors.apt_type
          }"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
        />

        <type-apartments
          v-if="!isCommerce"
          v-model="form.apt_type"
          v-bind="{
            label: isHouses ? 'Тип будинку' : 'Тип квартири',
            type: form.type,
            required: true,
            error: errors.apt_type,
            roomsError: errors.rooms
          }"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
          :rooms="form.rooms"
          @roomsChange="(val) => $set(this.form, 'rooms', val)"
        />

        <type-material
          v-if="isTypeMaterial"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
          v-model="form.material_type"
        />

        <select-config
          v-model="form.repair"
          v-bind="{
            title: 'Стан',
            required: true,
            error: errors.repair,
            multiple: false
          }"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-12', 'col-md-4']"
        />

        <div class="col-12 pt-3">
          <e-checkbox
            v-show="form.type === 'flat'"
            v-model="form.is_angled"
            label="Кутова"
            :class="[calculateClassForSmallComponent, 'angeled']"
          />
        </div>
      </context-wrapper>

      <!-- Block Параметри 3 -->
      <context-wrapper
        v-if="form.type"
        :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Параметри 3"
      >
        <numeric-type-field
          v-if="isSquareCommon"
          v-model="form.square_common"
          v-bind="{
            label: squareCommonLabel,
            required: true,
            error: errors.square_common
          }"
          :class="[
            calculateClassForSmallComponent,
            'mt-4',
            'col-6',
            'col-md-4'
          ]"
        />

        <numeric-type-field
          v-if="isApartments"
          v-model="form.square_living"
          v-bind="{ label: 'Житлова', error: errors.square_living }"
          :class="[
            calculateClassForSmallComponent,
            'mt-4',
            'col-6',
            'col-md-4'
          ]"
        />

        <numeric-type-field
          v-if="isSquareArea"
          v-model="form.square_area"
          v-bind="{
            label: 'К-сть соток',
            required: !isCommerce || form.apt_type === 'farm',
            error: errors.square_area
          }"
          :class="[
            calculateClassForSmallComponent,
            'mt-4',
            'col-6',
            'col-md-4'
          ]"
        />
        <numeric-type-field
          v-if="isRooms"
          v-model="form.rooms"
          v-bind="{
            label:
              form.apt_type === 'office_room'
                ? 'Кільк. кабінетів'
                : 'Кільк. кімнат',
            error: errors.rooms
          }"
          :class="[
            calculateClassForSmallComponent,
            'mt-4',
            'col-6',
            'col-md-4'
          ]"
        />

        <numeric-type-field
          v-if="!isCommerce"
          v-model="form.square_kitchen"
          v-bind="{ label: 'Кухня', error: errors.square_kitchen }"
          :class="[
            calculateClassForSmallComponent,
            'mt-4',
            'col-6',
            'col-md-4'
          ]"
        />

        <numeric-type-field
          v-if="isFloor"
          v-model="form.floor"
          v-bind="{
            label: 'Поверх',
            required: !isCommerce,
            error: errors.floor
          }"
          :class="[
            calculateClassForSmallComponent,
            'mt-4',
            'col-6',
            'col-md-4'
          ]"
        />

        <numeric-type-field
          v-if="isFloors"
          v-model="form.floors"
          v-bind="{
            label: 'Поверховість',
            required: !isCommerce,
            error: errors.floors
          }"
          :class="[
            calculateClassForSmallComponent,
            'mt-4',
            'col-6',
            'col-md-4'
          ]"
        />

        <numeric-type-field
          v-model="form.price"
          v-bind="{
            label: 'Вартість ' + (isSell ? '$' : '₴'),
            required: true,
            error: errors.price
          }"
          :class="[
            calculateClassForSmallComponent,
            'mt-4',
            'col-6',
            'col-md-4'
          ]"
        />

        <additions
          v-if="isAdditions"
          :class="[calculateClassForBigComponent, 'mt-4', 'col-6', 'col-md-4']"
          v-model="form.options"
          :apt_type="form.apt_type"
        />
      </context-wrapper>

      <!-- Block Параметри 4 -->
      <context-wrapper
        v-if="form.type && isHouses"
        :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Параметри 4"
      >
        <div class="row col-12">
          <communication-field
            v-model="form.heating"
            v-bind="{ title: 'Опалення' }"
            :arrayOptions="heating_object"
            :class="[calculateClassForMediumComponent, 'mt-4 col-3']"
          />

          <communication-field
            v-model="form.water"
            v-bind="{ title: 'Вода' }"
            :arrayOptions="water_object"
            :class="[calculateClassForMediumComponent, 'mt-4 col-3']"
          />

          <communication-field
            v-model="form.gas"
            v-bind="{ title: 'Газ' }"
            :arrayOptions="gas_object"
            :class="[calculateClassForMediumComponent, 'mt-4 col-3']"
          />

          <communication-field
            v-model="form.sewers"
            v-bind="{ title: 'Каналізація' }"
            :arrayOptions="sewers_object"
            :class="[calculateClassForMediumComponent, 'mt-4 col-3']"
          />
        </div>
      </context-wrapper>

      <!-- Block Параметри 5 -->
      <context-wrapper
        v-if="form.type"
        :class="[$bem('context-block'), 'row', 'ml-auto', 'mr-auto', 'mb-4']"
        title="Параметри 5"
      >
        <div class="addition-status">
          <div class="row pt-3 pl-2 pr-2">
            <div v-if="isRent && !isCommerce" class="col-12 col-lg-4 mb-3">
              <AdditionalOptions
                v-model="form.is_pets_allowed"
                :error="errors.is_pets_allowed"
                title="Можна з тваринами?"
                required
              />
            </div>

            <div v-if="isRentFlat" class="col-12 col-lg-4 mb-3">
              <AdditionalOptions
                v-model="form.is_children_allowed"
                :error="errors.is_children_allowed"
                title="Можна з дітьми?"
                required
              />
            </div>

            <div v-if="isRentFlat || isRentHouse" class="col-12 col-lg-4 mb-3">
              <AdditionalOptions
                v-model="form.is_foreigners_allowed"
                :error="errors.is_foreigners_allowed"
                title="Беруть іноземців?"
                required
              />
            </div>

            <div v-if="isSell" class="col-12 col-lg-4 mb-3">
              <AdditionalOptions
                v-model="form.is_cashless_payment_available"
                :error="errors.is_cashless_payment_available"
                title="Можливий безготівковий розрахунок?"
                required
              />
            </div>

            <div class="col-12 col-lg-4 mb-3">
              <AdditionalOptions
                v-model="form.is_used_for_advertising"
                :error="errors.is_used_for_advertising"
                title="Надати дозвіл колегам використовувати фото на рекламних майданчиках?"
                required
              />
            </div>

            <div class="col-12 col-lg-4 mb-3">
              <AdditionalOptions
                v-model="form.is_seller_pays_the_commission"
                :error="errors.is_seller_pays_the_commission"
                title="Продавець оплачує комісію?"
                required
              />
            </div>

            <div v-if="isShowStatusObject" class="col-12">
              <status-object v-model="form.status" />
            </div>
          </div>

          <div class="mt-4">
            <RichTextEditor v-model="form.text" placeholder="Опис" />
          </div>

          <div class="mt-3">
            <p>
              Будь ласка, переконайтесь в тому, що ви додаєте об'єкт по якому
              маєте прямий контакт з власником.
            </p>
          </div>
        </div>
      </context-wrapper>
    </div>

    <div v-if="form.type" class="row mt-4">
      <div class="flex-center">
        <e-btn
          :disabled="disabledButton"
          :class="['btn-large', $bem('btn-submit'), disabledButton]"
          @click="submit"
        >
          <span v-if="disabledButton" class="d-flex align-items-center">
            <div class="btn-loader mr-2">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>

            {{ isEdit ? 'Редагую' : 'Завантажую' }} об'єкт
          </span>

          <span v-else>{{ isEdit ? 'Редагувати' : 'Додати' }}</span>
        </e-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { forEach, isEmpty } from 'lodash';
import ContextWrapper from '@/components/context-wrapper';

import { mapActions, mapGetters } from 'vuex';
import component from '@/mixins/component';
import eInput from '@/elements/inputs/e-input';
import eCheckbox from '@/elements/inputs/e-checkbox';
import eTextarea from '@/elements/e-textarea';
import eBtn from '@/elements/buttons/e-btn';
import imageUploader from '@/components/app/image-uploader';
import typeRealEstate from '@/components/estate/type-real-estate';
import BusinessType from '@/components/business-type';
import locationSelect from '@/components/address/location';
import districtsSelect from '@/components/address/districts';
import microdistrictsSelect from '@/components/address/microdistricts';
import streetsSelect from '@/components/address/streets';
import residentialComplexSelect from '@/components/address/residential-complex';
import typeApartments from '@/components/estate/type-apartments';
import typeMaterial from '@/components/estate/type-material';
import labels from '@/components/popups/add-object-popup/v2/components/labels';
import additions from '@/components/popups/add-object-popup/v2/components/additions';
import selectConfig from '@/components/select-config';
import statusObject from '@/components/popups/add-object-popup/v2/components/status-object';
import AdditionalOptions from '@/components/popups/add-object-popup/v2/components/additional-options';
import RichTextEditor from '@/components/app/RichTextEditor.vue';

import {
  apartmentsValidate,
  defaultData,
  getMessage,
  prepareValidationFields
} from '@/components/popups/add-object-popup/v2/helpers';

import HouseLetters from '@/components/address/house-letters';
import NumericTypeField from '@/elements/inputs/numeric-input/numeric-input';
import CommunicationField from '@/components/popups/add-object-popup/v2/components/communication-field';
import LabelsMixin from '@/mixins/LabelsMixin';

const initialForm = {
  type: '',
  apt_type: '',
  business_type: null,
  city: null,
  district: [],
  microdistrict: [],
  street: [],
  material_type: '',
  labels: [],
  options: [],
  house_letter: '',
  price: null,
  floors: null,
  floor: null,
  user_label: [],
  house_number: '',
  residential_complex: [],
  building: [],
  square_common: null,
  square_living: null,
  square_kitchen: null,
  text: '',
  images: [],
  house_hull: '',
  repair: '',
  square_area: null,
  source: 'rieltor',
  rooms: 0,
  is_angled: false,
  water: '',
  sewers: '',
  gas: '',
  heating: '',

  is_pets_allowed: null,
  is_children_allowed: null,
  is_foreigners_allowed: null,
  is_cashless_payment_available: null,
  is_used_for_advertising: null,
  is_seller_pays_the_commission: null,
  status: false
};

export default {
  name: 'manageObjectFormPage',
  mixins: [component, LabelsMixin],
  components: {
    CommunicationField,
    NumericTypeField,
    HouseLetters,
    statusObject,
    eBtn,
    selectConfig,
    eTextarea,
    eCheckbox,
    eInput,
    labels,
    typeMaterial,
    typeApartments,
    residentialComplexSelect,
    streetsSelect,
    microdistrictsSelect,
    districtsSelect,
    locationSelect,
    BusinessType,
    typeRealEstate,
    imageUploader,
    additions,
    ContextWrapper,
    AdditionalOptions,
    RichTextEditor
  },
  data() {
    return {
      form: {
        ...initialForm
      },
      newImages: [],
      newSorting: [],
      activeObject: {},
      errors: {},
      statusObject: null,
      statusAdvertising: null,
      statusPaysCommission: null,
      cashlessPayments: null,
      statusAnimals: null,
      statusChildren: null,
      statusForeigners: null,
      disabledButton: false,
      imagesLimit: 36
    };
  },
  computed: {
    ...mapGetters(['editObject']),
    isEdit() {
      return !isEmpty(this.editObject);
    },
    modelCity: {
      get() {
        return this.form.city;
      },
      set(val) {
        this.$set(this.form, 'city', val);

        if (val) {
          this.handleGetDistrictByCities(val.id);
        }
      }
    },
    calculateClassForBigComponent: ({ $mq }) =>
      $mq !== 'xs' ? 'col-4' : 'col-6',
    calculateClassForSmallComponent: ({ $mq }) =>
      $mq !== 'xs' ? 'col-2' : 'col-4',
    calculateClassForMediumComponent: ({ $mq }) =>
      $mq !== 'xs' ? 'col-3' : 'col-4',
    isApartments: ({ form }) => form.type === 'flat',
    isCommerce: ({ form }) => form.type === 'commerce',
    isHouses: ({ form }) => form.type === 'house',
    isShowStatusObject: ({ form }) => form.status !== 'approved',
    squareCommonLabel: ({ form }) => {
      switch (form.type) {
        case 'house':
          return 'Пл. будинку м2';
        case 'flat':
          return 'Загальна';
        default:
          return 'Пл. М²';
      }
    },
    isRent() {
      return this.form.business_type === 'rent';
    },
    isSell() {
      return this.form.business_type === 'sell';
    },
    isRentFlat() {
      return this.form.type === 'flat' && this.form.business_type === 'rent';
    },
    isRentHouse() {
      return this.form.type === 'house' && this.form.business_type === 'rent';
    },
    isFloor: ({ form }) => {
      const apt_types = [
        'restaurant_or_cafe_or_bar',
        'office_room',
        'shop',
        'beauty_object',
        'health_object',
        'sport_object',
        'event_object'
      ];
      return (
        form.type === 'flat' ||
        (form.type === 'commerce' && apt_types.includes(form.apt_type))
      );
    },
    isFloors: ({ form }) => {
      const apt_types = [
        'recreation_center_or_hotel',
        'industrial_premise',
        'free_appointment_premise',
        'MAF',
        'autoservice',
        'trading_area',
        'farm',
        'trading_area',
        'production_object',
        'production_object'
      ];
      return (
        form.type === 'flat' ||
        form.type === 'house' ||
        (form.type === 'commerce' && !apt_types.includes(form.apt_type))
      );
    },
    isRooms: ({ form }) => {
      const apt_types = [
        'office_room',
        'detached_building',
        'part_of_the_building',
        'shop',
        'beauty_object',
        'health_object',
        'sport_object'
      ];

      return form.type === 'commerce' && apt_types.includes(form.apt_type);
    },
    isSquareArea: ({ form }) => {
      const apt_types = [
        'restaurant_or_cafe_or_bar',
        'warehouse_or_hangar',
        'recreation_center_or_hotel',
        'industrial_premise',
        'free_appointment_premise',
        'farm',
        'event_object',
        'production_object'
      ];
      return (
        form.type === 'house' ||
        (form.type === 'commerce' && apt_types.includes(form.apt_type))
      );
    },
    isAdditions: ({ form }) => {
      const apt_types = ['MAF', 'trading_area', 'farm'];
      return form.type === 'commerce' && !apt_types.includes(form.apt_type);
    },
    isTypeMaterial: ({ form }) =>
      !['autoservice', 'farm', 'trading_area'].includes(form.apt_type),
    isSquareCommon: ({ form }) => !['farm'].includes(form.apt_type)
  },
  watch: {
    form: {
      handler(newValue) {
        prepareValidationFields(newValue);

        if (!isEmpty(this.errors)) {
          this.errors = apartmentsValidate(newValue);
        }
      },
      deep: true
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== from.name) {
      this.close();
    }

    next();
  },
  mounted() {
    if (!isEmpty(this.editObject)) {
      this.form = JSON.parse(JSON.stringify(this.editObject));

      /**
       * Prepraring Options
       */
      this.statusObject = this.editObject.status === 'draft';
      this.form.status = this.form.status === 'draft';
    }
  },
  methods: {
    ...mapActions([
      'handleGetObjects',
      'handleSortingImagesByApartmentsId',
      'handleCreateApartment',
      'handleEditObject',
      'handleEditApartment',
      'handleExportImages',
      'handleGetDistrictByCities'
    ]),
    async submit() {
      if (this.disabledButton) return;
      this.disabledButton = true;

      const data = this.dataPreparationForSending();

      this.errors = apartmentsValidate(data);

      const validationFields = {
        flat: {
          rent: [
            'is_pets_allowed',
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_children_allowed',
            'is_foreigners_allowed'
          ],
          sell: [
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_cashless_payment_available'
          ]
        },
        house: {
          rent: [
            'is_pets_allowed',
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_foreigners_allowed'
          ],
          sell: [
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_cashless_payment_available'
          ]
        },
        commerce: {
          rent: ['is_used_for_advertising', 'is_seller_pays_the_commission'],
          sell: [
            'is_used_for_advertising',
            'is_seller_pays_the_commission',
            'is_cashless_payment_available'
          ]
        }
      };

      if (data.business_type) {
        const typeObject = validationFields[data.type || 'flat'];

        forEach(typeObject[data.business_type], (key) => {
          if (data[key] === null) {
            this.errors[key] = "Поле обов'язкове для заповнення";
          }
        });
      }

      if (!isEmpty(this.errors)) {
        this.disabledButton = false;
        return;
      }

      try {
        if (this.isEdit) {
          await this.editApartments(data);
        } else {
          await this.createApartments(data);
        }
      } catch (error) {
        this.$notify({
          group: 'app',
          duration: 6000,
          type: 'error',
          text: 'An unexpected error occurred. Please try again.'
        });
      } finally {
        this.disabledButton = false;
      }
    },
    dataPreparationForSending() {
      let data = this.form;

      const prepareData = JSON.parse(JSON.stringify(data));

      if (!isEmpty(data.labels)) {
        prepareData.labels = data.labels.map((item) => item.value);
      }

      prepareData.images = data.images ? data.images : [];
      prepareData.city_id = data.city?.id;
      prepareData.district_id = data.district?.id;
      prepareData.building_id = data.building?.id;
      prepareData.microdistrict = data.microdistrict?.id;
      prepareData.microdistrict_id = data.microdistrict?.id;
      prepareData.street_id = data.street?.id;
      prepareData.status = data && data.status ? 'draft' : 'created';
      // prepareData.is_cashless_payment_available =  this.cashlessPayments;
      // prepareData.is_used_for_advertising = this.statusAdvertising;
      // prepareData.is_seller_pays_the_commission = this.statusPaysCommission;

      if (this.isRentFlat) {
        // prepareData.is_pets_allowed = this.statusAnimals
        // prepareData.is_children_allowed = this.statusChildren
        // prepareData.is_foreigners_allowed = this.statusForeigners
      }

      prepareData.repair = data.repair?.id || data.repair;
      prepareData.gas = data.gas?.id || data.gas;
      prepareData.heating = data.heating?.id || data.heating;
      prepareData.sewers = data.sewers?.id || data.sewers;
      prepareData.water = data.water?.id || data.water;
      // prepareData.currency = data.business_type === 'sell' ? 1 : 2

      prepareData.business_type = data.business_type;

      if (!this.isHouses && data.apt_type !== 'farm') {
        delete prepareData.square_area;
      }
      if (this.isCommerce) {
        prepareData.options = data.options;
        prepareData.building_id = data.building?.id;
      } else {
        prepareData.residential_complex_id = data.residential_complex?.id;
        prepareData.rooms = data.rooms;
      }

      return prepareData;
    },
    async editApartments(data) {
      try {
        const res = await this.handleEditApartment(data);

        if (res.status === 200 || res.status === 204) {
          const message = getMessage(res.data.status);
          this.close();
          await this.updateSortingImage(res.data.id);

          this.$notify({
            group: 'app',
            duration: 6000,
            type: 'success',
            text: message
          });

          this.$router.push('/objects');
        } else {
          this.$notify({
            group: 'app',
            duration: 6000,
            type: 'error',
            text: res.data
          });
        }
      } catch (error) {
        this.$notify({
          group: 'app',
          duration: 6000,
          type: 'error',
          text:
            'An error occurred while editing the apartment. Please try again.'
        });
      } finally {
        this.disabledButton = false;
      }
    },
    async createApartments(data) {
      try {
        const {
          data: responseData,
          status: statusCode
        } = await this.handleCreateApartment(data);

        if (statusCode === 201 || statusCode === 204) {
          const message = getMessage(responseData.status);

          try {
            await this.submitImages(responseData);
            this.close();
            this.$router.push('/objects');

            this.$notify({
              group: 'app',
              duration: 6000,
              type: 'success',
              text: message
            });
          } catch (error) {
            this.$notify({
              group: 'app',
              duration: 6000,
              type: 'error',
              text: 'Failed to upload images. Please try again later.'
            });
          }
        } else {
          this.$notify({
            group: 'app',
            duration: 6000,
            type: 'error',
            text: responseData
          });
        }
      } catch (error) {
        this.$notify({
          group: 'app',
          duration: 6000,
          type: 'error',
          text:
            'An error occurred while creating the apartment. Please try again.'
        });
      } finally {
        this.disabledButton = false;
      }
    },
    updateSortingImage(realtyId) {
      this.handleSortingImagesByApartmentsId({
        realtyId,
        images: this.newSorting
      });
    },
    close() {
      this.model = undefined;
      this.clear();
      this.handleEditObject({});
    },
    clear() {
      this.form = JSON.parse(JSON.stringify(defaultData));
    },
    async submitImages(data) {
      if (this.newImages) {
        await this.handleExportImages({ id: data.id, images: this.newImages });
        this.handleGetObjects(data.type);
      }
    }
  }
};
</script>

<style lang="sass" src="@/assets/styles/pages/manage-object-form-page.sass" />
