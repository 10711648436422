<template>
  <div :class="$bem()">
    <div :class="$bem('inner')">
      <div :class="$bem('mb-invisible')">
        <form-container />
      </div>
      <div :class="$bem('description')">
        <h2>База перевіреної нерухомості</h2>
        <p>
          EasyBase - це перевірена і надійна інформація. Ми пропонуємо доступ
          лише для професійних ріелторів, що забезпечує високу якість та
          точність даних.
          <span :class="$bem('mb-invisible')"
            >Реєструйтесь вже сьогодні і отримайте безкоштовний доступ до нашої
            бази даних, щоб знайти найкращі пропозиції для своїх клієнтів.</span
          >
        </p>
        <div :class="$bem('mb-invisible')">
          <benefits-component />
        </div>
        <div :class="$bem('dt-invisible')">
          <form-container />
          <benefits-component />
        </div>
        <div :class="$bem('support-block')">
          <button v-b-modal.add-feedback-popup>
            <img src="@/assets/landing/chat.svg" alt="support" />
            <span>Підтримка</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import ItemComponent from './item-component.vue';
import BenefitsComponent from './benefits-component.vue';
import FormContainer from './form-container.vue';

export default {
  components: { ItemComponent, BenefitsComponent, FormContainer },
  name: 'landing-auth-screen',
  mixins: [component]
};
</script>
