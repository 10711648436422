export const state = {
  buildingType: localStorage.getItem('apiRoad') || 'flat',
  businessType: 'sell',
  searchId: ''
};

export const mutations = {
  SET_BUILDING_TYPE(state, type) {
    if (!['lands', 'garage'].includes(type)) {
      state.buildingType = type;
      localStorage.setItem('apiRoad', type);
    }
  },
  SET_BUSINESS_TYPE: (state, payload) => (state.businessType = payload),
  SET_SEARCH_ID: (state, payload) => (state.searchId = payload)
};

export const actions = {};
