<template>
  <div v-if="!isLoading">
    <div class="col-filter" v-if="windowWidth > 767">
      <div class="row">
        <div
          class="col res-modal-wrapp"
          :class="{ show: this.$parent.showFiltersModal }"
          ref="resFiltersModal"
        >
          <div class="block-marg"></div>

          <div class="filter-head-csontainer d-none d-lg-block">
            <div id="cabinet-component" class="cabinet-container">
              <h2 class="filter-main-title">Мої дані</h2>

              <div class="filter-marg" />

              <div class="form-required-container mb-3">
                <b-form-file
                  class="form-input photo-upload required"
                  v-model="userMe.picture"
                  id="picture"
                  :state="Boolean(userMe.picture)"
                  placeholder="додати фото"
                  drop-placeholder="додати фото"
                  @change="onFileChange"
                  multiple
                  name="change-avatar"
                  ref="uploadimage"
                />
              </div>

              <div class="text-center">
                <img
                  v-if="!userMe.photo"
                  class="avatar"
                  :src="require('@/assets/images/cabinet-photo.jpg')"
                  alt=""
                />

                <img
                  v-else
                  :src="userMe.photo"
                  :ref="`avatar`"
                  class="avatar"
                  alt=""
                />
              </div>

              <div class="form-group form-group-cabinet peronal-page">
                <button
                  class="btn-filter btn btn-lg w-100 text-center"
                  id="btn-my-site"
                  v-clipboard:copy="getLinkToPersonPage()"
                  v-clipboard:success="showMessage"
                  title="Тут посилання на ваш особистий сайт з усією базою нерухомості, але вже з вашим номером"
                >
                  Моя EasyHata
                </button>
              </div>

              <div class="form-group form-group-cabinet">
                <input
                  type="text"
                  class="form-input"
                  placeholder="Прізвище"
                  name="last-name"
                  v-model="userMe.last_name"
                />
              </div>

              <div class="form-group form-group-cabinet">
                <input
                  type="text"
                  class="form-input"
                  placeholder="Ім'я"
                  name="first-name"
                  v-model="userMe.first_name"
                />
              </div>

              <div
                class="form-group form-group-cabinet"
                v-for="(item, key) in userMe.phones"
                :key="key"
              >
                <input
                  type="number"
                  v-model="item.number"
                  class="form-input"
                  name="phones"
                  :disabled="item.is_main ? '' : false"
                  placeholder="Телефон"
                />
              </div>

              <div
                v-show="profilePhone"
                class="w-100 text-center form-group form-group-cabinet"
              >
                <a href="#" class="" @click.prevent="addPhone('profilePhone')">
                  +Додати телефон
                </a>
              </div>

              <div class="form-group form-group-cabinet">
                <input
                  type="text"
                  class="form-input"
                  placeholder="Агенція"
                  name="agency"
                  v-model="userMe.agency_name"
                />
              </div>

              <span class="product-hr" />

              <div style="height: 100%">
                <div class="w-100 text-center form-group form-group-cabinet">
                  <a href="#" v-b-toggle.collapsePass>Змінити пароль</a>
                </div>

                <b-collapse id="collapsePass">
                  <form class="px-0">
                    <div class="form-group form-group-cabinet">
                      <input
                        v-model="security.password_old"
                        @change="$v.security.password_old.$touch"
                        type="password"
                        name="password-old"
                        class="form-input"
                        placeholder="Старый пароль"
                      />
                    </div>

                    <div class="form-group form-group-cabinet">
                      <input
                        v-model="security.password_new"
                        @change="$v.security.password_new.$touch"
                        type="password"
                        name="password-new"
                        class="form-input"
                        placeholder="Новый пароль"
                      />
                    </div>

                    <div class="form-group form-group-cabinet">
                      <input
                        v-model="security.password_new_repeat"
                        @change="$v.security.password_new_repeat.$touch"
                        type="password"
                        name="password-new-repeat"
                        class="form-input"
                        placeholder="Повторить пароль"
                      />
                    </div>

                    <template v-if="security.password_new_repeat !== ''">
                      <div
                        class="input-error"
                        v-if="!$v.security.password_new_repeat.sameAsPassword"
                      >
                        Пароли должны совпадать
                      </div>
                    </template>
                  </form>
                </b-collapse>
              </div>

              <div class="form-group form-group-cabinet">
                <button
                  class="btn-filter btn btn-lg w-100 text-center"
                  :disabled="$v.$invalid"
                  @click="save"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>

          <div class="filter-marg"></div>
        </div>
      </div>
    </div>

    <div class="col-items">
      <div class="block-marg"></div>

      <div class="row justify-content-center justify-content-lg-start">
        <div
          class="col-10 mx-auto mb-4 col-xs-8 col-sm-6 col-md-5 d-lg-none"
          v-if="windowWidth < 767"
        >
          <div class="cabinet-pay-wrapper">
            <h2 class="filter-main-title" id="account">Мої дані</h2>

            <div class="filter-marg" />

            <div class="form-required-container-mobile">
              <b-form-file
                class="form-input photo-upload required"
                v-model="userMe.picture"
                :state="Boolean(userMe.picture)"
                placeholder="додати фото"
                drop-placeholder="додати фото"
                @change="onFileChange"
                multiple
                name="change-avatar"
                ref="uploadimage"
              />
            </div>

            <div class="text-center">
              <img
                v-if="!userMe.photo"
                class="avatar"
                :src="require('@/assets/images/cabinet-photo.jpg')"
                alt=""
              />

              <img
                v-else
                :src="userMe.photo"
                :ref="`avatar`"
                class="avatar"
                alt=""
              />
            </div>

            <div class="form-group form-group-cabinet peronal-page">
              <button
                class="btn-filter btn btn-lg w-100 text-center"
                v-clipboard:copy="getLinkToPersonPage()"
                v-clipboard:success="showMessage"
                title="Тут посилання на ваш особистий сайт з усією базою нерухомості, але вже з вашим номером"
              >
                Моя EasyHata
              </button>
            </div>

            <div class="form-group form-group-cabinet">
              <input
                type="text"
                name="last-name"
                class="form-input"
                placeholder="Прізвище"
                v-model="userMe.last_name"
              />
            </div>

            <div class="form-group form-group-cabinet">
              <input
                type="text"
                name="first-name"
                class="form-input"
                placeholder="Ім'я"
                v-model="userMe.first_name"
              />
            </div>

            <div
              class="form-group form-group-cabinet"
              v-for="(item, key) in userMe.phones"
              :key="key"
            >
              <input
                type="number"
                v-model="item.number"
                class="form-input"
                :disabled="item.is_main ? '' : false"
                name="phones"
                placeholder="Телефон"
              />
            </div>

            <div class="w-100 text-center form-group form-group-cabinet">
              <a href="#" class="" @click.prevent="addPhone">+Додати телефон</a>
            </div>

            <div class="form-group form-group-cabinet">
              <input
                type="text"
                class="form-input"
                placeholder="Агенція"
                name="agency"
                v-model="userMe.agency_name"
              />
            </div>

            <span class="product-hr" />

            <div class="form-group form-group-cabinet">
              <a
                href="#"
                class="btn-filter btn-lg w-100 text-center"
                @click="save"
              >
                Зберегти
              </a>
            </div>

            <div class="form-group form-group-cabinet">
              <router-link to="/logout">
                <button class="btn-filter btn btn-lg w-100 text-center">
                  Вийти
                </button>
              </router-link>
            </div>
          </div>
        </div>

        <div class="wrapp-pay-block">
          <div class="col-auto">
            <div class="cabinet-pay-wrapper">
              <h2 class="filter-main-title" id="payments">Оплата</h2>

              <div class="filter-marg"></div>

              <div
                class="d-flex flex-column flex-sm-row justify-content-start align-items-sm-start align-items-center"
              >
                <div class="cabinet-pay-block">
                  <div
                    class="cabinet-pay-inner text-center d-flex flex-column align-items-center"
                  >
                    <span class="text-upper text-bold cabinet-pay-title">
                      Підписка на базу
                    </span>

                    <div class="cabinet-pay-desc flex-center">
                      <div>
                        Підписка зі зниженням вартості:
                        <span class="text-blue">
                          1-й місяць - {{ subscriptionCost.firstMonth }} грн.,
                          2-й - {{ subscriptionCost.secondMonth }} грн., 3-й
                          місяць - {{ subscriptionCost.thirdMonth }} грн.
                          <!-- <svg
                            class="icon question-icon ml-2"
                            id="tooltip-subscribe"
                          >
                            <use
                              xlink:href="@/assets/icons/question.svg#question"
                            ></use>
                          </svg>. -->
                          <!-- <b-tooltip
                            target="tooltip-subscribe"
                            triggers="hover"
                          >
                            Зниження вартості працює в тому випадку, якщо
                            перерва між закінченням доступу та нової оплати не
                            перевищує 2 дні. Ціна {{ subscriptionCost.thirdMonth }} грн. зберігається до тих пір,
                            поки не буде проведена перерва в оплаті! Якщо перерва в
                            оплаті перевищує 2 доби, вартість повертається на {{ subscriptionCost.firstMonth }} грн.
                            (Ми подбаємо про те, щоб нагадати Вам про оплату вчасно,
                            1 місяць дорівнює 28 дням). Авторизуйтесь у Телеграм БОТі,
                            щоб отримувати нагадування "натисни щоб відкрити (https://t.me/Easyhata_bot)"
                          </b-tooltip> -->

                          <div style="color: white; text-align: justify">
                            <br />
                            Зниження вартості працює в тому випадку, якщо
                            перерва між закінченням доступу та нової оплати не
                            перевищує 2 дні. Ціна
                            {{ subscriptionCost.thirdMonth }} грн. зберігається
                            до тих пір, поки не буде проведена перерва в оплаті!
                            Якщо перерва в оплаті перевищує 2 доби, вартість
                            повертається на
                            {{ subscriptionCost.firstMonth }} грн. (Ми подбаємо
                            про те, щоб нагадати Вам про оплату вчасно, 1 місяць
                            дорівнює 28 дням). <br />

                            <span style="color: #12b9c3">
                              Авторизуйтесь у Телеграм БОТі, щоб отримувати
                              нагадування "<a
                                href="https://t.me/Easyhata_bot"
                                style="
                                  color: #12b9c3;
                                  font-weight: bold;
                                  text-decoration: underline;
                                "
                                >натисни щоб відкрити</a
                              >"
                            </span>
                          </div>
                        </span>

                        <span class="space-top text-blue">
                          Проведи з нами 6 місяців та отримай 7-й у подарунок
                        </span>
                      </div>
                    </div>

                    <div class="profile__balance">
                      Баланс: {{ meCoins }} Easycoins
                    </div>

                    <div class="container-buttons">
                      <div
                        class="cabinet-pay-btn btn-filter"
                        @click="paymantEasycoins('realty_access')"
                      >
                        Оплата Easycoins
                      </div>

                      <div
                        class="cabinet-pay-btn d-flex align-items-end liqpay-btn"
                        v-html="userMe.payment_forms.realty_access"
                      ></div>
                    </div>

                    <p class="space-top">
                      <span>
                        Кількість безперервних оплат
                        {{ userMe.payment_count }} </span
                      ><br /><br />
                      <span>
                        До нарахування бонусного місяця <br />
                        залишилося
                        {{ 6 - (userMe.payment_count % 6) }} безперервних оплат </span
                      ><br /><br />
                      Підписка діє до:
                      <span
                        :class="
                          this.currentDiff > 5 ? 'green-text' : 'red-text'
                        "
                      >
                        {{ userMe.subscriptions_valid_until.realty_access }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-auto">
            <div class="cabinet-pay-wrapper">
              <h2 class="filter-main-title" id="payments">Оплата</h2>
              <div class="filter-marg"></div>
              <div
                class="d-flex flex-column flex-sm-row justify-content-start align-items-sm-start align-items-center"
              >
                <div class="cabinet-pay-block">
                  <div
                    class="cabinet-pay-inner text-center d-flex flex-column align-items-center"
                  >
                    <span
                      class="text-upper text-bold cabinet-pay-title green-text"
                      >Збережений фільтр у персональній сторінці</span
                    >
                    <p style="text-align: justify; padding-top: 15px">
                      <span class="green-text"
                        >
                        Збережений фільтр у персональній сторінці дає
                        можливість відправляти добірку клієнту із заздалегідь
                        відфільтрованим списком!
                      </span>
                      Також відфільтрований список можна перетворити на QR code і
                      розмістити його як рекламу на банерах, розклейках, бордах і
                      т.д. приклад (дивися, що продається в цьому будинку/на цій
                      вулиці/на цьому районі тощо)
                    </p>
                    <div class="profile__balance">
                      Баланс: {{ meCoins }} Easycoins
                    </div>
                    <div class="container-buttons">
                      <div>
                        <div style="font-size: 10px">
                          100 EasyCoin (28 днів)
                        </div>
                        <div>
                          <div
                            class="btn-pay-easycoins"
                            @click="paymantEasycoins('share_link')"
                          >
                            Оплата Easycoins
                          </div>
                        </div>
                      </div>
                      <div
                        style="
                          -webkit-transform: scale(0.75);
                          transform: scale(0.75);
                          padding-top: 10px;
                        "
                      >
                        <div>100грн (28 днів)</div>
                        <div>
                          <div v-html="userMe.payment_forms.share_link"></div>
                        </div>
                      </div>
                    </div>
                    <p class="space-top">
                      Підписка діє до:
                      <span
                        :class="
                          this.currentDiffFilterUrl > 5
                            ? 'green-text'
                            : 'red-text'
                        "
                      >
                        {{ userMe.subscriptions_valid_until.share_link }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="footer-p">
        <b-button v-b-modal.terms-popup>Умови та правила</b-button>
        <div class="cards">
          <img
            src="@/assets/images/logo/mastercard-logo.svg"
            alt="mastercard"
          />
          <img src="@/assets/images/logo/visa_logo.svg" alt="visa" />
        </div>
      </div>
    </div>

    <TermsConditionsPopup />
  </div>
</template>

<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators';
import { mapActions, mapState, mapMutations } from 'vuex';
import TermsConditionsPopup from '@/components/popups/TermsConditionsPopup';
import { diffDates } from '@/utils/diff-dates';
import api from '@/api';
import Vue from 'vue';

export default {
  name: 'ProfilePage',
  components: {
    TermsConditionsPopup
  },
  data() {
    return {
      windowWidth: null,
      security: {
        password_old: '',
        password_new: '',
        password_new_repeat: ''
      },
      coins: null,
      exclusives_count: 2,
      allow: true,
      profilePhone: true,
      phonesLocale: [],
      currentDiff: NaN,
      currentDiffFilterUrl: NaN,
      isLoading: false,
      subscriptionCost: {
        firstMonth: 550,
        secondMonth: 530,
        thirdMonth: 500
      }
    };
  },
  validations() {
    if (this.security.password_new !== '') {
      return {
        userMe: {
          last_name: { required },
          first_name: { required }
        },
        security: {
          password_old: {
            required,
            minLength: minLength(6)
          },
          password_new: {
            required,
            minLength: minLength(6)
          },
          password_new_repeat: {
            sameAsPassword: sameAs('password_new')
          }
        }
      };
    }
    return {
      userMe: {
        last_name: { required },
        first_name: { required }
      },
      security: {
        password_old: {},
        password_new: {},
        password_new_repeat: {}
      }
    };
  },
  computed: {
    ...mapState({
      defaultPhoto: (state) => state.profile.defaultPhoto,
      userMe: (state) => state.profile.userMe
    }),
    meCoins() {
      return this.coins !== null ? this.coins : this.userMe.easy_coins;
    }
  },
  methods: {
    ...mapActions(['handleUpdateUser', 'handleGetUser']),
    ...mapMutations(['PROFILE_DATA']),
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async paymantEasycoins(type) {
      try {
        const response = await api.paymentEasycoins(type);

        if (response.status === 200) {
          this.$notify({
            group: 'app',
            type: 'success',
            text: 'Оплата пішла успішно'
          });
        }

        if (response.data.coins) {
          this.coins = response.data.coins;
        }
      } catch (e) {
        if (e.response.status === 400) {
          if (e.response.data.error && e.response.data.error.length) {
            Object.values(e.response.data.error).forEach((val) => {
              Vue.prototype.$notify({ group: 'app', type: 'error', text: val });
            });
          }
        }
      }
    },
    addPhone(field) {
      const defaultPhone = {
        number: '',
        is_main: false
      };
      if (this.userMe.phones.length >= 4) {
        this.$notify({
          group: 'app',
          type: 'error',
          text: 'Максимум 4 телефони'
        });
        if (field === 'profilePhone') {
          this.profilePhone = false;
        } else {
          this.profilePhone = true;
        }
      } else {
        this.userMe.phones.push(defaultPhone);
      }
    },
    onFileChange(e) {
      const selectedFiles = e.target.files;
      for (let i = 0; i < selectedFiles.length; i++) {
        this.userMe.photo = selectedFiles[i];
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.userMe.photo = reader.result;
      };
      reader.readAsDataURL(this.userMe.photo);
    },

    async save() {
      const data = this.userMe;
      let realtyData = {};
      for (const prop in data) {
        if (prop != 'payment_form') {
          realtyData[prop] = data[prop];
        }
      }

      realtyData.unions = realtyData.unions.map((union) => union.id);

      if (this.defaultPhoto === realtyData.photo) {
        delete realtyData.photo;
      }

      let phones = realtyData.phones.filter(
        (phone) => phone.number && !phone.is_main
      );
      phones = phones.map((phone) => phone.number.replace('+38', ''));
      realtyData.phones = phones;

      if (
        this.security.password_new != '' &&
        this.security.password_new_repeat != '' &&
        this.security.password_old != ''
      ) {
        realtyData = { ...realtyData, ...this.security };
      }
      await this.handleUpdateUser(realtyData).then((res) => {
        if (res.status === 200) {
          this.PROFILE_DATA(false);
          this.$notify({
            group: 'app',
            type: 'success',
            text: 'Дані профілю успішно оновлені'
          });
        }
      });
    },
    showMessage() {
      this.$notify({
        group: 'app',
        type: 'success',
        text: 'Посилання скопійовано'
      });
    },
    getLinkToPersonPage() {
      return `${process.env.VUE_APP_URL_EASYHATA}/${this.userMe.id}`;
    }
  },
  async created() {
    this.isLoading = true;

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    await this.handleGetUser();

    const todayData = new Date();
    const oldDate = this.userMe.subscriptions_valid_until.realty_access
      .split('-')
      .reverse()
      .join('-');
    // const oldDateFilterUrl = this.userMe.subscriptions_valid_until.share_link
    //   .split('-')
    //   .reverse()
    //   .join('-');

    this.currentDiff = diffDates(new Date(oldDate), todayData);
    // this.currentDiffFilterUrl = diffDates(
    //   new Date(oldDateFilterUrl),
    //   todayData
    // );

    this.isLoading = false;
  }
};
</script>

<style lang="sass">
.container-buttons
  display: flex
  justify-content: space-between
  align-items: center

.btn-pay-easycoins
    display: flex
    border-radius: 5px
    background-color: #308b9d
    padding: 8px
    &:hover
        cursor: pointer

.cabinet-pay-btn.btn-filter
    display: flex
    align-items: center
    border-radius: 5px
    height: 35px

.input-error
  color: $error_color

.footer-p
    padding-left: 40px
    padding-bottom: 40px

    button
        margin-bottom: 8px

    .cards
        margin-left: 8px

        img
            margin: 0 8px
            height: 30px

            @include media-breakpoint-up(sm)
                height: 50px

.hide
    display: none

.red-text
    color: red
.green-text
  color: #12b9c3
.avatar
    width: 128px
    height: 128px
    object-fit: cover
</style>
<style>
.photo-upload input {
  display: none;
}

.photo-upload {
  width: auto;
  margin: 0 auto 20px auto;
  max-width: 120px;
}

.photo-upload.custom-file {
  display: flex;
}
.close-modal {
  display: none;
  position: absolute;
  top: 70px;
  right: 30px;
  z-index: 101;
  width: 20px;
  height: 20px;
}

.close-modal.show {
  display: block;
}

.close-modal:after,
.close-modal:before {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3px;
  width: 20px;
  content: '';
  transform: translateY(-50%);
  background-color: #fff;
}

.close-modal:after {
  transform: rotate(45deg);
}
.close-modal:before {
  transform: rotate(-45deg);
}
.photo-upload .custom-file-label {
  height: 100%;
  margin: 0;
  line-height: 33px;
  overflow: hidden;
  background-color: #308b9d;
  padding: 0 8px;
  border-radius: 22px;
  color: white;
  display: flex;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-weight: 600;
  border: none;
  text-overflow: ellipsis;
}

.custom-file-label:hover {
  cursor: pointer;
}

.custom-file-label:after {
  display: none;
}
.photo-upload .custom-file-label::after {
  display: none;
}

.res-navbar-wrapper {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  left: 0;
  top: 0;
  height: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.4s ease 0s, height 0s ease 0.4s;
}

iframe {
  position: absolute;
  top: 50%;
  bottom: 50%;
  z-index: 101;
  transform: translateY(-50%);
}
.res-navbar-wrapper.show {
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.4s ease 0s, height 0s ease 0s;
}
.liqpay-btn {
  transform: scale(0.75);
}
.profile__balance {
  margin-bottom: 6px;
}
.question-icon {
  width: 20px;
  height: 20px;
}
.tooltip-inner {
  max-width: 300px;
  width: 100%;
}

.peronal-page {
  padding-top: 20px;
  padding-bottom: 7px;
}

.peronal-page > button {
  white-space: nowrap;
  font-size: 12px;
}

.wrapp-pay-block {
  display: flex;
  flex-wrap: wrap;
}

.wrapp-pay-block > .col-auto {
  flex: 1;
}

#btn-my-site:hover {
  color: white;
}

.form-required-container-mobile {
  padding-bottom: 15px;
}
</style>
