<template>
  <div class="image-carousel">
    <div ref="imagesCarousel" class="f-carousel">
      <div
        v-for="image in images"
        class="f-carousel__slide"
        :data-thumb-src="image"
      >
        <div class="image-carousel__img">
          <a :href="image" data-fancybox="gallery">
            <img :data-lazy-src="image" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

import { Carousel } from '@fancyapps/ui/dist/carousel/carousel.esm.js';
import '@fancyapps/ui/dist/carousel/carousel.css';

import { Thumbs } from '@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js';
import '@fancyapps/ui/dist/carousel/carousel.thumbs.css';

export default {
  props: {
    images: {
      type: Array,
      required: true
    },
    noDownload: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      carouselOptions: {
        infinite: false,
        Dots: false,
        slidesPerPage: 1
      },
      containerEl: null,
    };
  },
  mounted() {
    this.containerEl = this.$refs.imagesCarousel;

    this.bindFancybox();

    new Carousel(this.containerEl, this.carouselOptions, { Thumbs });

    if(this.noDownload) {
      this.protectImgDownload()
    }
  },
  updated() {
    Fancybox.unbind(this.containerEl);
    Fancybox.close();

    this.bindFancybox();
  },
  unmounted() {
    Fancybox.destroy();
  },
  methods: {
    bindFancybox() {
      Fancybox.bind(this.containerEl, '[data-fancybox]', {
        Toolbar: {
          display: {
            right: ["iterateZoom", "fullscreen", "close"],
          }
        }
      });
    },
    protectImgDownload() {
      this.containerEl.addEventListener('contextmenu', function(e) {
        if (e.target.tagName === 'IMG') {
          e.preventDefault();
        }
      }); 
    }
  }
};
</script>

<style lang="sass">
.f-carousel
  --f-carousel-theme-color: var(--color-primary)

  &__thumbs
    --f-thumb-width: 96px
    --f-thumb-height: 3rem
    --f-thumb-outline: 0
    --f-thumb-outline-color: #5eb0ef
    --f-thumb-opacity: 1
    --f-thumb-hover-opacity: 1
    --f-thumb-selected-opacity: 1
    --f-thumb-border-radius: 2px
    --f-thumb-offset: 0px
    --f-button-next-pos: 0
    --f-button-prev-pos: 0

    &.is-modern
      --f-thumb-gap: 0.5rem
      --f-thumb-extra-gap: 0.75rem
      --f-thumb-clip-width: 3rem

.fancybox
  &__nav
    --f-button-color: var(--color-primary)
    --f-button-hover-color: var(--color-primary)

.image-carousel
  &__img
    position: relative
    display: block
    padding-bottom: 56%

    img
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      object-fit: cover
</style>
