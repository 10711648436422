<template>
  <div :class="$bem()">
    <e-input
      v-bind="{ label: 'Тип нерухомості', error, required: true }"
      readonly
      v-model="selectedLabel"
      :disabled="disabled"
      @click="handleOpen"
    />

    <e-menu v-bind="{ right: true, top: true }" v-model="opened" class="p-2">
      <div v-for="(item, i) in options" :key="i">
        <e-radio
          v-if="item.value !== 'commerce' || isEnabledCommerce"
          v-model="selected"
          v-bind="{ value: item.value, label: item.label }"
          @close="opened = undefined"
          style="line-height: 1.2"
        />
      </div>
    </e-menu>
  </div>
</template>

<script>
import { TYPE_CONSTANTS } from '../../../helpers/constants';
import eMenu from '@/elements/e-menu';
import { objectToArray } from '@/utils/object-to-array';
import eRadio from '@/elements/inputs/e-radio';
import eInput from '@/elements/inputs/e-input';
import component from '@/mixins/component';

export default {
  name: 'type-real-estate',
  components: { eInput, eRadio, eMenu },
  mixins: [component],
  data: () => ({
    opened: false
  }),
  computed: {
    isHouses: ({ form }) => form.type === 'house',
    options() {
      return objectToArray(TYPE_CONSTANTS).slice(0, 3);
    },
    selectedLabel({ value }) {
      return value ? TYPE_CONSTANTS[this.value] : '';
    },

    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    handleOpen() {
      this.opened = true;
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    isAptsItem: {
      type: Boolean,
      default: false
    },
    isEnabledCommerce: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>
