<template>
  <div class="radio-additional-options" :class="{ _error: error }">
    <div v-if="error" class="wrapper-business-type__error-message">
      <span>Поле обов'язкове для заповнення</span>
    </div>

    <div style="font-size: 16px">
      <span>{{ title }}</span>

      <span v-if="required" class="text-danger"> *</span>
    </div>

    <div class="d-flex">
      <div class="form-check pr-3">
        <input
          :value="true"
          v-model="model"
          class="form-check-input"
          type="radio"
          :id="title + 'yes'"
        />

        <label class="form-check-label additional-option" :for="title + 'yes'">
          Так
        </label>
      </div>

      <div class="form-check">
        <input
          :value="false"
          v-model="model"
          class="form-check-input"
          type="radio"
          :id="title + 'no'"
        />

        <label class="form-check-label additional-option" :for="title + 'no'">
          Ні
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'additional-options',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    error: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
};
</script>
