<template>
  <div class="rich-text-editor">
    <vue-editor
      ref="editor"
      v-model="localContent"
      :editor-options="editorSettings"
      :editor-toolbar="editorToolbar"
      :placeholder="placeholder"
      @text-change="handleTextChange"
    />

    <p class="rich-text-editor__portion">
      {{ plainTextLength }} / {{ maxChars }} символів
    </p>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'RichTextEditor',
  components: {
    VueEditor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxChars: {
      type: Number,
      default: 3000
    }
  },
  data() {
    return {
      localContent: this.value,
      plainTextLength: 0,
      editorToolbar: ['bold', 'italic', 'underline', 'strike'],
      editorSettings: {
        modules: {
          clipboard: {
            matchers: [[Node.ELEMENT_NODE, this.customQuillClipboardMatcher]]
          }
        }
      }
    };
  },
  watch: {
    value(newValue) {
      if (newValue !== this.localContent) {
        this.localContent = newValue;
      }
    }
  },
  methods: {
    customQuillClipboardMatcher(node, delta) {
      delta.ops = delta.ops.map((op) => ({
        insert: op.insert
      }));
      return delta;
    },
    handleTextChange() {
      const editor = this.$refs.editor.quill;
      const htmlContent = editor.root.innerHTML;

      const plainText = editor.getText().trim();
      this.plainTextLength = plainText.length;

      if (this.plainTextLength > this.maxChars) {
        editor.deleteText(this.maxChars, this.plainTextLength);
      }

      this.localContent =
        this.plainTextLength <= this.maxChars ? htmlContent : this.localContent;

      this.localContent = this.plainTextLength > 0 ? this.localContent : '';

      this.$emit('input', this.localContent);
    }
  }
};
</script>

<style lang="sass">
.rich-text-editor
  &__portion
    padding-left: 0.5rem
    padding-top: 0.125rem
    font-size: 0.75rem
    font-weight: 300
    line-height: 1.2
    color: var(--color-primary)
</style>
