<template>
  <div :class="$bem()">
    <h2>Працюємо для Вас</h2>
    <div :class="$bem('inner')">
      <img src="@/assets/landing/map.png" alt="map" />
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';

export default {
  name: 'landing-workfor-screen',
  mixins: [component]
};
</script>
