<template>
  <div :class="$bem()">
    <div :class="$bem('inner')">
      <div :class="$bem('description')">
        <h2>Нам довіряють</h2>
        <p>
          Наш сайт - це відмінний інструмент для професійних ріелторів, які
          прагнуть ефективно працювати та збільшувати свої доходи. Досвідчені
          фахівці вже відзначили наш сервіс як надійний та перевірений, що дає
          їм можливість зекономити свій час та зосередитися на роботі з
          клієнтами. Приєднуйтеся до наших успішних користувачів та
          зареєструйтеся на нашому сайті вже сьогодні!
        </p>
      </div>
      <div :class="$bem('reviews')">
        <img
          src="@/assets/landing/reviews/sticker-cat.png"
          alt="sticker"
          :class="$bem('sticker')"
        />
        <reviews-slider />
      </div>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import reviewsSlider from './reviews-slider.vue';

export default {
  components: { reviewsSlider },
  mixins: [component]
};
</script>
