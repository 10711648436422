<template>
  <a
    class="useful-btn"
    :href="to"
    :target="target"
  >
    <slot>
      Відкрити
    </slot>
  </a>
</template>

<script>
export default {
  name: "UsefulBtn",
  props: {
    to: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style lang="sass" scoped>
.useful-btn
  width: 100%
  max-width: 132px
  padding: 14px 0
  background: $white_easy
  border-radius: 10px
  text-decoration: none
  font-weight: 600
  font-size: 14px
  line-height: 140%
  font-variant: small-caps
  text-align: center
  color: $landingColor
  transition: all .3s ease
  &:hover
    background: $title-info
    color: $white_easy
    box-shadow: 0 2px 10px 0 #002e30
</style>