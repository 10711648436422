<template>
  <div :class="[{ 'not-empty': !!value }, $bem()]">
    <input-wrapper v-bind="{ ...$attrs, showErrorMarker: false }" v-on="{...$listeners,  click: () => $emit('click')}">
      <template #input="{ on, isError }">
        <input
          :class="[$bem('input')]"
          v-bind="{
            name,
            value,
            type,
            required,
            disabled,
            size,
            readonly,
            placeholder,
            ...nativeProps
          }"
          v-on="{
            ...$listeners,
            ...on,
            click: () => $emit('click'),
            input: (e) => $emit('input', e.target.value)
          }"
          v-input-autofocus="autofocus"
        />

        <label v-show="!!label" :class="[$bem('label')]" v-bind="{ disabled }">
          {{ label }}
          <slot name="label" v-bind="{ label, isError }" />
          <sub v-show="required" :class="$bem('required')">*</sub>
        </label>
      </template>
    </input-wrapper>
  </div>
</template>

<script>
import component from '@/mixins/component';

import inputWrapper from '../input-wrapper';

export default {
  name: 'e-input',
  inheritAttrs: false,
  mixins: [component],
  components: { inputWrapper },
  computed: {
    isError() {
      const { error } = this;

      if (Array.isArray(error)) return !!error.length;

      return !!error;
    }
  },
  directives: {
    'input-autofocus': {
      bind(el, { value }) {
        if (value) el.focus();
      },
      update(el, { value }) {
        if (value) el.focus();
      }
    }
  },
  props: {
    name: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    value: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    // s = 40px, l = 56px, m = 64px, xl = 72px
    size: {
      type: String,
      default: undefined
    },
    nativeProps: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
