import { render, staticRenderFns } from "./AddItemButton.vue?vue&type=template&id=ad2585aa&scoped=true"
import script from "./AddItemButton.vue?vue&type=script&lang=js"
export * from "./AddItemButton.vue?vue&type=script&lang=js"
import style0 from "./AddItemButton.vue?vue&type=style&index=0&id=ad2585aa&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad2585aa",
  null
  
)

export default component.exports