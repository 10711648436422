/* eslint-disable */
require('./archive_icon')
require('./build_add_icon')
require('./builds_icon')
require('./caret_down')
require('./check_icon')
require('./cloud_icon')
require('./double_down_icon')
require('./down_icon')
require('./download_icon')
require('./edit_icon')
require('./email_icon')
require('./filter_icon')
require('./fullscreen_icon')
require('./list_add_icon')
require('./list_icon')
require('./mail_add_icon')
require('./menu_icon0')
require('./menu_icon1_1')
require('./menu_icon1')
require('./menu_icon10')
require('./menu_icon11')
require('./menu_icon2')
require('./menu_icon3')
require('./menu_icon4')
require('./menu_icon5')
require('./menu_icon6')
require('./menu_icon7')
require('./menu_icon8')
require('./menu_icon9')
require('./menu_icon12')
require('./menu_inner_icon0')
require('./menu_inner_icon1')
require('./menu_inner_icon2')
require('./menu_inner_icon3')
require('./menu_inner_icon4')
require('./menu_inner_icon5')
require('./menu_inner_icon6')
require('./menu_inner_icon7')
require('./menu_inner_icon8')
require('./menu_inner_icon9')
require('./menu_istochnic_icon1')
require('./menu_istochnic_icon2')
require('./menu_istochnic_icon3')
require('./partners_icon')
require('./pay_icon')
require('./person_icon1')
require('./person_icon2')
require('./person_icon3')
require('./person_icon4')
require('./person_icon5')
require('./person_icon6')
require('./photo_add_icon')
require('./plus_icon')
require('./profile_icon')
require('./required_icon')
require('./search')
require('./trash_icon')
require('./user_plus_icon')
require('./view_icon')
require('./why_icon')
require('./why')
require('./menu_option')