<template>
  <div class="filter-header">
    <div class="filter-head-container">
      <h2 class="filter-main-title" id="page-title">
        {{ this.$route.meta.title }}
      </h2>
      <div class="filter-marg"></div>
      <div class="base-text">Мої об'єкти</div>
      <b-dropdown
        class="dropdown-apartments ml-1"
        :text="type_constant_plural[$store.state.my_objects.buildingType]"
      >
        <b-dropdown-item
          v-for="(label, index) in types"
          @click="updateBuildingType(index)"
          :key="index"
          id="dropdown-menu"
        >
          {{ label }}
          <span class="status-options" v-if="index === 'lands' || index === 'garage'"> (скоро) </span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import LabelsMixin from '@/mixins/LabelsMixin';
import { mapActions } from 'vuex';

export default {
  name: 'FilterObjects',
  mixins: [LabelsMixin],
  data() {
    return {
      businessType: [
        {
          key: 'sell',
          name: 'Продам'
        },
        {
          key: 'rent',
          name: 'Здам'
        }
      ]
    };
  },
  methods: {
    ...mapActions(['handleGetObjects']),
    updateBuildingType(type) {
      this.$store.commit('SET_BUILDING_TYPE_OBJECT', type);
      this.handleGetObjects();
    }
  },
  computed: {
    getNameBusinessType() {
      return this.businessType.find(
        (i) => i.key === this.$store.state.filter_header.businessType
      ).name;
    }
  }
};
</script>
