<template>
  <div class="useful-page">
    <div class="useful-page-container">
      <UsefulItem
        v-for="(item, index) in usefulItems"
        :key="index"
        :item="item"
      />
    </div>
    <MobileHeader
      :objectsCount="objects.results.length"
      :totalCount="resultsCount"
      v-if="windowWidth < 991"
    />
  </div>
</template>

<script>
import UsefulItem from '@/components/useful/useful-item.vue'
import MobileHeader from '@/components/app/mobile-header/mobile-header.vue';
import { mapState } from 'vuex';

export default {
  name: 'UsefulPage',
  components: {
    MobileHeader,
    UsefulItem
  },
  data() {
    return {
      windowWidth: null,
      usefulItems: [
        {
          img: require('@/assets/images/useful/item-0.png'),
          title: 'Відео посібник',
          href: 'https://www.youtube.com/playlist?list=PLzFo6Bgw8Y95fIYS8WA_zhmYJZZt8a-rT',
          target: '_blank'
        },
        {
          img: require('@/assets/images/useful/item-3.jpg'),
          title: 'Iнновації у нерухомості №1',
          href: 'https://www.youtube.com/watch?v=8CbJBSV96ZI',
          target: '_blank'
        },

        {
          img: require('@/assets/images/useful/item-4.jpg'),
          title: 'Iнновації у нерухомості №2',
          href: 'https://www.youtube.com/watch?v=YEZ3tUC7O7U',
          target: '_blank'
        },
        {
          img: require('@/assets/images/useful/item-1.png'),
          title: 'Гайд по фото',
          href: '/EasyPhoto.pdf',
          target: '_blank'
        },
        {
          img: require('@/assets/images/useful/item-2.png'),
          title: 'Дія. Освіта',
          href: 'https://osvita.diia.gov.ua/courses/real-estate-agent',
          target: '_blank'
        },
      ]
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    ...mapState({
      objects: (state) => state.my_objects.objects
    }),
    resultsCount() {
      return this.objects.count || 0;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
}
</script>

<style lang="sass" scoped>
.useful-page
  width: 100%
  flex-grow: 0
  padding: 120px 16px
  .mobile-header
    top: 0
    right: 0
    z-index: 100
  @media (max-width: 768px)
    padding-top: 60px
    padding-bottom: 60px
  &-container
    width: 100%
    max-width: 1140px
    margin-left: auto
    margin-right: auto
    display: flex
    justify-content: space-around
    align-items: normal
    flex-wrap: wrap
</style>