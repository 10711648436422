<template>
  <div :class="$bem()" :style="{ minHeight }">
    <div :class="$bem('inner')">
      <div>
        <img
          src="@/assets/images/logo/EasyBase_logo_4.png"
          alt="EasyBase"
          :class="$bem('logo')"
        />
      </div>
      <div>
        <ul :class="$bem('menu')">
          <li>
            <a
              @click="scrollTo('home')"
              :class="{ active: activeSection === 'home' }"
            >
              Головна
            </a>
          </li>
          <li>
            <a
              @click="scrollTo('functional')"
              :class="{ active: activeSection === 'functional' }"
            >
              Функціонал
            </a>
          </li>
          <li>
            <a
              @click="scrollTo('about')"
              :class="{ active: activeSection === 'about' }"
            >
              Про нас
            </a>
          </li>
          <li>
            <a
              @click="scrollTo('contacts')"
              :class="{ active: activeSection === 'contacts' }"
            >
              Контакти
            </a>
          </li>
        </ul>
      </div>
      <button :class="$bem('btn-try')" v-b-modal.try-free-popup>
        Спробувати безкоштовно
      </button>
      <try-free-popup />
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import TryFreePopup from '@/components/popups/try-free-popup';
import { LANDING_HEADER_HEIGHT } from '@/helpers/constants';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'landing-header',
  mixins: [component],
  components: { TryFreePopup },
  data() {
    return {
      minHeight: `${LANDING_HEADER_HEIGHT}px`
    };
  },
  computed: {
    ...mapGetters(['activeSection'])
  },
  methods: {
    ...mapActions(['setActiveSection']),
    scrollTo(id) {
      this.setActiveSection(id);
      if (id === 'home') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        const element = document.getElementById(id);
        const y =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          LANDING_HEADER_HEIGHT;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }
};
</script>
