<template>
  <div class="useful-item">
    <img class="useful-item--img" :src="item.img" alt="">
    <div class="useful-item--wrapper">
      <div class="useful-item--title">
        {{ item.title }}
      </div>
      <UsefulBtn
        :to="item.href"
        :target="item.target"
        class="useful-item--button"
      >
        Відкрити
      </UsefulBtn>
    </div>
  </div>
</template>

<script>
import UsefulBtn from '@/components/useful/useful-btn.vue'

export default {
  name: 'UsefulItem',
  components: {
    UsefulBtn
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="sass">
.useful-item
  width: 100%
  max-width: 326px
  min-height: 182px
  border-radius: 10px
  position: relative
  overflow: hidden
  margin-bottom: 20px

  &--wrapper
    z-index: 10
    width: 100%
    display: flex
    justify-content: space-between
    align-items: flex-start
    position: absolute
    padding: 12px
    bottom: 0
    left: 0

  &::after
    content: ''
    width: 100%
    min-height: 100%
    position: absolute
    top: 6px
    z-index: 6
    filter: blur(2px)
    background: linear-gradient(180deg, rgba(209, 217, 226, 0) 45.58%, rgba(161, 179, 196, 0.85) 61.69%, rgba(78, 111, 144, 0.85) 77.28%, rgba(9, 12, 16, 0.85) 98.5%)

  &--title
    font-weight: 600
    font-size: 20px
    line-height: 140%
    font-variant: small-caps
    color: $white_easy

  &--img
    position: absolute
    z-index: 5
    width: 100%
    object-fit: cover

</style>