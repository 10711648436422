import api from '@/api';

export const state = {
  objects: {
    count: null,
    next: 1,
    previous: null,
    results: []
  },
  loaderObject: false,
  editObject: {},
  buildingType: 'flat'
};

export const getters = {
  editObject: (state) => state.editObject
};

export const mutations = {
  MY_OBJECTS(state, payload) {
    state.objects = payload;
  },
  LOADER_OBJECT(state, payload) {
    state.loaderObject = payload;
  },
  EDIT_OBJECT(state, payload) {
    state.editObject = payload;
  },
  CLEAN_MY_OBJECTS(state) {
    state.editObject = {};
  },
  CLEAN_STORE(state) {
    state.objects = {
      count: null,
      next: 1,
      previous: null,
      results: []
    };
  },
  SET_BUILDING_TYPE_OBJECT(state, type) {
    if (!['lands', 'garage'].includes(type)) {
      state.buildingType = type;
    }
  }
};

export const actions = {
  async handleGetObjects({ state, commit }, params) {
    try {
      commit('LOADER_OBJECT', true);

      const response = await api.getMyObjects(params, state.buildingType);
      if (response.status === 200) {
        if (
          response.data.next === 2 ||
          (!response.data.previous && !response.data.next)
        ) {
          commit('MY_OBJECTS', response.data);
        } else {
          commit('MY_OBJECTS', {
            ...response.data,
            results: [...state.objects.results, ...response.data.results]
          });
        }
      }
    } catch (e) {
      console.error('errorMessage', e);
    } finally {
      commit('LOADER_OBJECT', false);
    }
  },
  async handleCreateApartment({ commit, dispatch }, payload) {
    try {
      commit('LOADER_OBJECT', true);
      const response = await api.handleCreateApartment(payload, payload.type);
      // TO DO AFTER BACKEND: REPLACE WITH MUTATION
      dispatch('handleGetObjects', payload.type);
      commit('LOADER_OBJECT', false);
      return response;
    } catch (e) {
      console.log('errorMessage', e);
      return e.response;
    }
  },

  async handleCreateImagesByApartmentsId(_, payload) {
    try {
      const response = await api.handleCreateImagesByApartmentsId(
        payload,
        payload.type
      );

      return response;
    } catch (e) {
      console.log('errorMessage', e);
    }
  },
  async handleDeleteImageByApartmentsId({ commit }, payload) {
    console.log(payload, 'payload');
    try {
      return await api.handleDeleteImageByApartmentsId(payload, payload.type);
    } catch (e) {
      console.log('errorMessage', e);
    }
  },

  async handleSortingImagesByApartmentsId({ commit }, payload) {
    try {
      await api.handleSortingImagesByApartmentsId(payload, payload.type);
    } catch (e) {
      console.log('errorMessage', e);
    }
  },

  async handleEditApartment({ commit, dispatch }, payload) {
    try {
      commit('LOADER_OBJECT', true);
      const response = await api.handleEditApartment(
        payload.id,
        payload,
        payload.type
      );
      commit('LOADER_OBJECT', false);
      dispatch('handleGetObjects', payload.type);
      return response;
    } catch (e) {
      console.error('errorMessage', e);
      return e.response;
    }
  },
  handleExportImages(context, payload) {
    return api.handleExportImages(payload.id, payload.images, payload.type);
  },
  handleEditObject({ commit }, payload) {
    commit('EDIT_OBJECT', payload);
  }
};
