var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$bem()},[_c('label',{class:[_vm.$bem('label'), _vm.labelFocused? _vm.$bem('label-focused'): '']},[_vm._v(_vm._s(_vm.title))]),_c('div',{class:_vm.$bem('wrapper')},[(_vm.required)?_c('span',{class:_vm.$bem('required-icon')},[_vm._v("*")]):_vm._e(),_c('input-wrapper',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"input",fn:function(ref){
var on = ref.on;
var isError = ref.isError;
return [_c('multiselect',_vm._g(_vm._b({class:_vm.$bem('input'),attrs:{"close-on-select":true,"clear-on-select":false,"show-no-results":false,"show-labels":false},on:{"open":_vm.open,"close":_vm.close},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'multiselect',Object.assign({}, {placeholder: _vm.placeholder,
            label: _vm.label,
            trackBy: _vm.trackBy,
            options: _vm.options,
            multiple: _vm.multiple},
            _vm.$attrs),false),_vm.$listeners))]}}])},'input-wrapper',Object.assign({}, _vm.$attrs, {showErrorMarker: false}),false),_vm.$listeners))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }