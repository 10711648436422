<template>
  <div id="contacts" :class="$bem()">
    <img
      src="@/assets/images/logo/EasyBase_logo_4.png"
      alt="EasyBase"
      :class="$bem('logo')"
    />
    <div :class="$bem('social')">
      <a :href="INSTAGRAM_LINK" target="_blank">
        <img src="@/assets/landing/instagram.svg" alt="instagram" />
      </a>
      <a :href="FACEBOOK_LINK" target="_blank">
        <img src="@/assets/landing/facebook.svg" alt="facebook" />
      </a>
      <a :href="VIBER_LINK" target="_blank">
        <img src="@/assets/landing/viber.svg" alt="viber" />
      </a>
      <a :href="TELEGRAM_LINK" target="_blank">
        <img src="@/assets/landing/telegram.svg" alt="telegram" />
      </a>
    </div>
    <p>© 2023 EasyBase. Всі права захищені.</p>
  </div>
</template>

<script>
import component from '@/mixins/component';
import {
  TELEGRAM_LINK,
  VIBER_LINK,
  INSTAGRAM_LINK,
  FACEBOOK_LINK
} from '@/helpers/constants';

export default {
  name: 'landing-footer',
  mixins: [component],
  data() {
    return {
      TELEGRAM_LINK,
      VIBER_LINK,
      INSTAGRAM_LINK,
      FACEBOOK_LINK
    };
  }
};
</script>
