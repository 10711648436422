<template>
  <div
    :class="{
      'stock-item-fluid': fluid,
      'stock-item': true,
    }"
  >
    <img
      :src="item.img"
      alt=""
      class="stock-item--img"
    >
    <span class="stock-item--title">
      {{ item.title }}
    </span>
    <p class="stock-item--description">
      {{ item.description }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'StockItem',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    fluid: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass" scoped>
.stock-item
  width: 100%
  max-width: 240px
  min-height: 228px
  background: $white_easy
  padding: 30px 20px
  border-radius: 20px
  display: flex
  flex-direction: column
  justify-content: flex-start
  align-items: center
  text-align: center
  font-weight: 300
  margin-bottom: 42px
  box-sizing: border-box
  @media (max-width: 768px)
    max-width: calc(50% - 8px)
    min-height: 240px
    padding: 10px 14px
    margin-bottom: 16px
  &-fluid
    max-width: 100%
    margin-top: 42px
    @media (max-width: 768px)
      min-height: 100px
      padding-top: 16px
      margin-top: 30px
      margin-bottom: 30px
  &--img
    width: 100%
    max-width: 50px
    margin-bottom: 8px
    @media (max-width: 768px)
      max-width: 36px
  &--title
  font-size: 24px
  line-height: 140%
  font-variant: small-caps
  color: $landingColor
  @media (max-width: 768px)
    font-size: 17px

  &--description
    font-size: 16px
    line-height: 150%
    text-align: center
    color: $landingColor
</style>