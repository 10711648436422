<template>
  <div class="v-search-page">
    <div v-if="isDesktop" class="v-search-page__aside col-filter">
      <ClientFilter @scrollVertical="handleVerticalScroll" />
    </div>

    <div class="v-search-page__main col-items base-items">
      <div
        v-if="!isLoaded"
        class="v-search-page__no-results v-search-page__no-results--start"
      >
        <picture>
          <source
            srcset="@/assets/images/no-results-04-pc.webp"
            media="(min-width: 991px)"
          />

          <img
            src="@/assets/images/no-results-04.webp"
            alt="EasyBase | Choose a client"
            width="320"
            height="320"
          />
        </picture>
      </div>

      <div v-show="isLoaded" class="v-search-page__content">
        <div v-show="hasResults">
          <simplebar
            v-show="isDesktop"
            data-simplebar-auto-hide="false"
            class="simplebar-horizontal-only"
            id="catalog-scroll"
          >
            <ul class="v-search-page__pc-list catalog-item-container">
              <li v-for="apt in objects" :key="apt.id">
                <b-tooltip
                  v-if="apt.is_archive === '1'"
                  :target="`tooltip-target-${apt.id}`"
                  triggers="hover"
                >
                  Об'єкт продано, через 3 дні після підтвердження буде
                  перенесено в архів!
                </b-tooltip>

                <catalog-card
                  v-if="isShowObjects"
                  :id="`tooltip-target-${apt.id}`"
                  :apartment="apt"
                  :edit="true"
                />
              </li>
            </ul>
          </simplebar>

          <div v-if="isMobile" class="v-search-page__mob-wrap">
            <ul class="v-search-page__mob-list">
              <li v-for="apt in objects" :key="apt.id">
                <catalog-card :apartment="apt" />
              </li>
            </ul>

            <infinite-loading
              v-if="objects.length > 0"
              ref="infiniteLoading"
              @infinite="infiniteHandler"
            >
              <div slot="spinner" class="loader-infinity"></div>
            </infinite-loading>
          </div>
        </div>

        <div v-if="!hasResults" class="v-search-page__no-results">
          <img
            src="@/assets/images/no-results-02.webp"
            alt="EasyBase | No search results"
            width="320"
            height="320"
          />
        </div>

        <div
          v-if="isDesktop"
          class="v-search-page__search-results item-search-container"
        >
          <span class="item-search-label">Знайдено</span>

          <div class="navbar-count">
            {{ this.objects.length }}/{{ this.count }}
          </div>
        </div>
      </div>

      <mobile-header
        v-if="isMobile"
        :objectsCount="this.objects.length"
        :totalCount="this.count"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Simplebar from 'simplebar-vue';
import CatalogCard from '@/components/app/CatalogCard';
import InfiniteLoading from 'vue-infinite-loading';
import { NOTIFICATIONS } from '../helpers/constants';
import MobileHeader from '../components/app/mobile-header/mobile-header.vue';

export default {
  name: 'MiddleClientComponent',
  components: {
    Simplebar,
    CatalogCard,
    InfiniteLoading,
    ClientFilter: () => import('@/components/clients/ClientFilter'),
    MobileHeader
  },
  data() {
    return {
      windowWidth: 0,
      isAllowStaticShowObjects: false,
      objectsPopup: false,
      isLoaded: false,
      bodyEl: null,
      scrollContentEl: null
    };
  },
  computed: {
    ...mapState({
      objects: (state) => state.main.objects,
      page: (state) => state.main.page,
      count: (state) => state.main.count,
      loaderObject: (state) => state.main.loaderObject,
      selectedClient: (state) => state.clients.selectedClient
    }),
    isShowObjects() {
      return !(this.loaderObject && !this.isAllowStaticShowObjects);
    },
    hasResults() {
      return this.objects && this.objects.length;
    },
    isDesktop() {
      return this.windowWidth > 991;
    },
    isMobile() {
      return this.windowWidth < 991;
    }
  },
  watch: {
    loaderObject: {
      handler(value) {
        if (!value && this.selectedClient) {
          this.isLoaded = true;
        }
      }
    }
  },
  created() {
    this.handleGetMicroDistrict();
    this.handleGetStreet();
    this.handleGetResidentialComplex();
    this.handleGetCities();
  },
  mounted() {
    this.bodyEl = document.body;
    this.scrollContentEl = document.querySelector('.catalog-item-container');

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.isAllowStaticShowObjects = false;
    this.getGroupsClientFilters();

    this.SET_PAGE(1);
    this.SET_COUNT(0);
    this.$store.commit('SET_OBJECTS', []);

    if (this.isDesktop) {
      this.setupHorizontalScroll();
    }
  },
  destroyed() {
    if (this.isDesktop) {
      window.removeEventListener('resize', this.handleResize);

      if (this.scrollBar) {
        this.scrollBar.removeEventListener('scroll', this.handleScroll);
      }

      window.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    ...mapActions([
      'handleGetMicroDistrict',
      'handleGetStreet',
      'handleGetResidentialComplex',
      'handleGetCities',
      'getObjects',
      'getGroupsClientFilters'
    ]),
    ...mapMutations(['SET_PAGE', 'SET_COUNT']),
    editObject(apartment) {
      this.objectsPopup = true;
      this.apartments = apartment;
    },
    async infiniteHandler($state) {
      if (this.objects.length > 0 && this.$store.state.main.page) {
        await this.getObjects({ isClient: true });
        $state.loaded();
      }
    },
    handleVerticalScroll(page) {
      this.getGroupsClientFilters(page);
    },
    handleScroll() {
      if (!this.hasResults) return;

      const sumValue = Math.ceil(
        this.scrollBar.offsetWidth + this.scrollBar.scrollLeft
      );

      if (
        this.isDesktop &&
        this.scrollContentEl &&
        sumValue >= this.scrollContentEl.offsetWidth
      ) {
        if (!this.page) {
          // isset next page
          this.$notify({
            group: 'app',
            type: 'success',
            text: NOTIFICATIONS.viewedAllObjects,
            ignoreDuplicates: true
          });
          return;
        }

        this.isAllowStaticShowObjects = true;
        this.getObjects({ isClient: true });
      }
    },
    handleResize() {
      this.windowWidth = window.innerWidth;

      this.setViewportHeight();
    },
    setupHorizontalScroll() {
      this.scrollBar = document.querySelector('.simplebar-content-wrapper');

      if (this.scrollBar) {
        this.scrollBar.addEventListener('scroll', this.handleScroll);
      }

      window.addEventListener('scroll', this.handleScroll);
    },
    setViewportHeight() {
      if (!this.bodyEl) return;

      this.bodyEl.style.setProperty(
        '--viewport-height',
        `${window.innerHeight}px`
      );
    }
  }
};
</script>

<style lang="sass" scoped>
.col-filter
  font-size: 16px

.tooltip
  opacity: 1

.v-search-page
  &__main
    position: relative

  &__content
    display: flex
    flex-direction: column
    flex: 1 1 auto

    @include media-breakpoint-up(md)
      padding-left: 1rem

    @include media-breakpoint-down(md)
      padding-top: 3rem

  &__search-results
    position: absolute
    z-index: 9
    left: 0
    bottom: 0.5rem

    @include media-breakpoint-down(md)
      display: none !important

  &__no-results
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    flex: 1 1 auto
    padding: 2rem

    &--start
      @include media-breakpoint-down(md)
        justify-content: flex-end
        flex: 0 0 calc(var(--viewport-height) - var(--header-height))
        padding-bottom: 0.5rem

    img
      max-width: 100%
      max-height: 100%
      object-fit: contain

      @include media-breakpoint-down(md)
        width: 15rem
        height: 15rem

  &__pc-list
    list-style: none
    padding-left: 0

  &__mob-list
    list-style: none
    padding-left: 0
</style>
